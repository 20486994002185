import React from 'react';
import PropTypes from 'prop-types';
import { getAppLogo } from '@utils/common';
import MainLogo from './styled-components/Logo';

function Logo({ large, flexStart, withMargin, newRebrandingFlag, isHeader }) {
  return (
    <MainLogo
      large={large}
      flexStart={flexStart}
      withMargin={withMargin}
      src={getAppLogo(newRebrandingFlag, isHeader)}
      data-testid="main-logo"
    />
  );
}

Logo.defaultProps = {
  flexStart: false,
};

Logo.propTypes = {
  large: PropTypes.bool,
  withMargin: PropTypes.bool,
  newRebrandingFlag: PropTypes.bool.isRequired,
  flexStart: PropTypes.bool,
  isHeader: PropTypes.bool,
};

export default Logo;
