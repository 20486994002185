import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import ErrorHandler from './ErrorHandler';

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorHandler));
