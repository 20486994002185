/* eslint-disable no-param-reassign,consistent-return */
import produce from 'immer';
import successAction from '@global/actions/successAction';
import failAction from '@global/actions/failAction';
import ModuleStateModel from './defaultState';
import * as modelKeys from './constants';
import * as actions from './actionTypes';

const initialState = ModuleStateModel;

const getNextInternalId = orders => {
  if (orders.length) {
    return (
      orders.reduce((prev, current) => (+prev.internalId > +current.internalId ? prev : current))
        .internalId + 1
    );
  }
  return 0;
};

const getIsValidPhone = phoneValue => {
  if (!phoneValue || isNaN(phoneValue)) return false;
  return phoneValue.toString().length > 4 && phoneValue.toString().length < 15;
};

const getIsValidCollectAtDropOff = (collectAtDropOff, cashCollectionConfiguration) => {
  if (
    !cashCollectionConfiguration?.hasCollectAtDropOff ||
    collectAtDropOff === 0 ||
    !collectAtDropOff
  )
    return true;
  return collectAtDropOff <= cashCollectionConfiguration?.maxAmountToCollect;
};

function ordersBulkModuleReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actions.PROCESS_ROWS: {
        const { orders } = action?.payload;
        draft[modelKeys.ORDERS] = orders;
        break;
      }

      case actions.ADD_NEW_ORDER: {
        const orders = [...state[modelKeys.ORDERS]];
        const nextInternalId = getNextInternalId(orders);
        const order = {
          internalId: nextInternalId,
          isValidating: false,
          addedManually: true,
        };
        orders.unshift(order);
        draft[modelKeys.ORDERS] = orders;
        break;
      }

      case actions.VALIDATE_BRANCHES: {
        const { branches, orders, partner } = action?.payload;

        const { cashCollectionConfiguration } = partner;
        orders.forEach((o, i) => {
          if (o.addedManually) return;
          const order = { ...o };
          const branchSelected = branches.find(b => {
            const branchAlias = (b?.alias || b?.id).toString().toLowerCase();
            const orderAlias = order?.alias.toString().toLowerCase();
            return branchAlias === orderAlias;
          });
          if (!branchSelected) {
            order.errors = {
              ...o.errors,
              branchId: modelKeys.INVALID_BRANCH,
            };
            order[modelKeys.schemaNames.ALIAS] = '';
          }
          order.invalidPhone = !getIsValidPhone(order.phoneNumber);
          order.invalidCollectAtDropOff = !getIsValidCollectAtDropOff(
            order.collectAtDropOff,
            cashCollectionConfiguration,
          );
          draft[modelKeys.ORDERS][i] = order;
        });
        break;
      }

      case actions.UPDATE_ORDER: {
        const { order } = action?.payload;
        const { internalId } = order;
        const index = state[modelKeys.ORDERS].findIndex(x => x.internalId === internalId);
        draft[modelKeys.ORDERS][index] = {
          ...state[modelKeys.ORDERS][index],
          ...order,
        };
        break;
      }

      case actions.SET_SCHEDULE_DATE: {
        const { scheduleDate } = action?.payload;
        draft[modelKeys.SCHEDULE_DATE] = scheduleDate;
        break;
      }

      case actions.SET_SCHEDULE_TIME: {
        const { scheduleTime } = action?.payload;
        draft[modelKeys.SCHEDULE_TIME] = scheduleTime;
        break;
      }

      case actions.DELETE_ORDER: {
        const { internalId } = action?.payload;
        const index = state[modelKeys.ORDERS].findIndex(x => x.internalId === internalId);
        draft[modelKeys.ORDERS].splice(index, 1);
        break;
      }

      case actions.DELETE_SELECTED_ORDERS: {
        draft[modelKeys.ORDERS] = state[modelKeys.ORDERS].filter(x => !x.selected);
        break;
      }

      case actions.CLEAR_ORDERS: {
        draft[modelKeys.ORDERS] = initialState[modelKeys.ORDERS];
        break;
      }

      case actions.CLEAR_FILE_ORDERS: {
        draft[modelKeys.ORDERS] = state[modelKeys.ORDERS].filter(x => x.addedManually);
        break;
      }

      case actions.CLEAR_BULK_STORE: {
        draft[modelKeys.ORDERS] = initialState[modelKeys.ORDERS];
        draft[modelKeys.SCHEDULE_DATE] = initialState[modelKeys.SCHEDULE_DATE];
        draft[modelKeys.SCHEDULE_TIME] = initialState[modelKeys.SCHEDULE_TIME];
        draft[modelKeys.IS_SENDING_ORDERS] = initialState[modelKeys.IS_SENDING_ORDERS];
        draft[modelKeys.ORDERS_WAS_SENT] = initialState[modelKeys.ORDERS_WAS_SENT];
        break;
      }

      case actions.CREATE_ORDERS: {
        const { orders } = action?.payload;
        draft[modelKeys.IS_SENDING_ORDERS] = true;
        draft[modelKeys.ORDERS] = orders.map(order => ({
          ...order,
          successfullySent: undefined,
        }));
        draft[modelKeys.ORDERS_WAS_SENT] = true;
        break;
      }

      case actions.TOGGLE_ALL_SELECTED: {
        const { selected } = action?.payload;
        draft[modelKeys.ORDERS] = state[modelKeys.ORDERS].map(order => ({
          ...order,
          selected,
        }));
        break;
      }

      case failAction({ type: actions.CREATE_ORDERS }): {
        draft[modelKeys.IS_SENDING_ORDERS] = false;
        break;
      }

      case successAction({ type: actions.CREATE_ORDERS }): {
        draft[modelKeys.IS_SENDING_ORDERS] = false;
        break;
      }

      default:
        return draft;
    }
  });
}

export default ordersBulkModuleReducer;
