/* eslint-disable no-param-reassign,consistent-return */
import produce from 'immer';
import successAction from '@global/actions/successAction';
import { ADDRESS_TYPE } from '@modules/location-manager/constants';
import * as modelKeys from './constants';
import * as actions from './actionTypes';
import ModuleStateModel from './defaultState';
const initialState = ModuleStateModel;

function ordersModuleReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case successAction({ type: actions.FETCH_PARTNER_BRANCHES }): {
        const data = action.payload;
        draft[modelKeys.BRANCHES] = data
          .map(x => ({
            ...x,
            addressType: ADDRESS_TYPE.BRANCH,
          }))
          .sort((a, b) => (a.alias < b.alias ? -1 : 1));
        break;
      }

      case successAction({ type: actions.FETCH_BRANCHES_BY_TEXT }): {
        const data = action.payload.slice(0, 2);
        draft[modelKeys.BRANCHES_FILTERS] = data;
        break;
      }

      case successAction({ type: actions.FETCH_ALL_FREQUENTS_ADDRESSES }): {
        const data = action.payload;
        draft[modelKeys.FREQUENT_ADDRESSES] = data.map(x => ({
          ...x,
          addressType: ADDRESS_TYPE.FREQUENT,
        }));
        break;
      }

      case successAction({ type: actions.FETCH_FREQUENTS_ADDRESS_BY_TEXT }): {
        const data = action.payload;
        draft[modelKeys.FREQUENT_ADDRESSES_FILTERS] = data
          .map(x => ({
            ...x,
            addressType: ADDRESS_TYPE.FREQUENT,
          }))
          .sort((a, b) => (a.usedCount > b.usedCount ? -1 : 1))
          .slice(0, 2);
        break;
      }

      case successAction({ type: actions.FETCH_AUTOCOMPLETE_ADDRESSES }): {
        const { predictions } = action.payload;
        draft[modelKeys.AUTOCOMPLETE_ADDRESSES] = predictions
          .map(x => ({
            ...x,
            addressStreet: x.description,
            addressType: ADDRESS_TYPE.AUTOCOMPLETE,
          }))
          .slice(0, 5);
        break;
      }

      case successAction({ type: actions.FETCH_PARTNER_BRANCHES_BY_ID }): {
        draft[modelKeys.ADDRESS_SELECTED] = action.payload;
        break;
      }
      case successAction({ type: actions.FETCH_FREQUENTS_ADDRESSES_BY_ID }): {
        draft[modelKeys.ADDRESS_SELECTED] = action.payload;
        break;
      }

      case successAction({ type: actions.DELETE_PARTNER_BRANCH }): {
        const { branchId } = action.payload;
        const index = state[modelKeys.BRANCHES].findIndex(
          x => x.id === branchId,
        );
        if (index > -1) {
          draft[modelKeys.BRANCHES].splice(index, 1);
        }
        break;
      }

      case successAction({ type: actions.DELETE_FREQUENTS_ADDRESS }): {
        const { id, frequentAddressId } = action.payload;
        const addressId = id || frequentAddressId;
        const index = state[modelKeys.FREQUENT_ADDRESSES].findIndex(
          x => x.id === addressId,
        );
        if (index > -1) {
          draft[modelKeys.FREQUENT_ADDRESSES].splice(index, 1);
        }

        const indexFrequentFiltered = state[
          modelKeys.FREQUENT_ADDRESSES_FILTERS
        ].findIndex(x => x.id === addressId);
        if (indexFrequentFiltered > -1) {
          draft[modelKeys.FREQUENT_ADDRESSES_FILTERS].splice(
            indexFrequentFiltered,
            1,
          );
        }
        break;
      }

      case successAction({ type: actions.UPDATE_PARTNER_BRANCH }): {
        const { branchId } = action.payload;
        const index = state[modelKeys.BRANCHES].findIndex(
          x => x.id === branchId,
        );
        if (index > -1) {
          draft[modelKeys.BRANCHES][index] = action.payload;
        }
        break;
      }

      case successAction({ type: actions.SAVE_PARTNER_BRANCH }): {
        draft[modelKeys.BRANCHES].push(action.payload);
        break;
      }

      case actions.CLEAR_ADDRESSES: {
        draft[modelKeys.AUTOCOMPLETE_ADDRESSES] = [];
        draft[modelKeys.FREQUENT_ADDRESSES_FILTERS] = [];
        draft[modelKeys.BRANCHES_FILTERS] = [];
        break;
      }

      default:
        return draft;
    }
  });
}

export default ordersModuleReducer;
