import track from '@tracking';
import getSelectedCountry from '@global/selectors/getSelectedCountry';
import { logError } from '@logs/logger';

export const trackUserIdentify = loggedUser => (dispatch, getState) => {
  const EVENT_NAME = 'user.identify';
  const state = getState();
  const selectedCountry = getSelectedCountry(state);

  try {
    const payload = {
      event: EVENT_NAME,
      userId: loggedUser.id,
      userType: loggedUser.userType,
      countryHostname: selectedCountry.name,
    };

    if (loggedUser.partnerId) {
      payload.shopId = loggedUser.partnerId;
    }

    track(EVENT_NAME, payload);
  } catch (error) {
    logError({ message: `Error on Tracking (GTM) : ${EVENT_NAME}`, error });
  }
};
