import getCareCenterInstances from '@services/hooks/getCareCenterInstances';
import { save } from '@services/sessionStorage';
import { SESSION_STORAGE_KEY } from '@modules/contact-support/care-center/constants';

function deleteCareCenterInstance(instance) {
  const instances = getCareCenterInstances() || [];

  const indexInHistory = instances.findIndex(x => x.id === instance.id);
  if (indexInHistory === -1) return null;

  instances.splice(indexInHistory, 1);

  save(SESSION_STORAGE_KEY, JSON.stringify(instances));
  return instances;
}

export default deleteCareCenterInstance;
