import { createSelector } from 'reselect';
import { STOP_NAVIGATION } from '@orders-multiple/constants';
import getPropFromModule from './getPropFromModule';

const getStopNavigation = createSelector(
  state => getPropFromModule(state, STOP_NAVIGATION),
  stop => stop,
);

export default getStopNavigation;
