import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  width: 90%;
  margin: 0;
  justify-content: space-between;
`;

export default ButtonsContainer;
