import { createSelector } from 'reselect';
import { SESSION_ID } from '@global/constants';

import getPropFromModule from './getPropFromModule';

const getSessionId = createSelector(
  state => getPropFromModule(state, SESSION_ID),
  sessionId => sessionId,
);

export default getSessionId;
