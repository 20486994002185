export const TourType = {
  Credentials: 'Credentials',
  ApiToken: 'ApiToken',
  CreateShipment: 'CreateShipment',
  CreateShipmentTokenApi: 'CreateShipmentTokenApi',
  MyShipments: 'MyShipments',
  Billing: 'Billing',
  MyShipmentsExportLabels: 'MyShipmentsExportLabels',
};

export const initialTours = [TourType.CreateShipment, TourType.Credentials];

export const toursAvailable = [
  TourType.CreateShipment,
  TourType.CreateShipmentTokenApi,
  TourType.Credentials,
  TourType.ApiToken,
  TourType.Billing,
  TourType.MyShipmentsExportLabels,
];

export const CLASSNAME_FTU_CREDENTIALS = {
  STEP_ONE: 'ftu_credentials_step_one',
  STEP_TWO: 'ftu_credentials_step_two',
  STEP_THREE: 'ftu_credentials_step_three',
};

export const CLASSNAME_FTU_TOKEN_API = {
  STEP_ONE: 'ftu_token_api_step_one',
  STEP_TWO: 'ftu_token_api_step_two',
};

export const CLASSNAME_FTU_CREATE_SHIPMENT = {
  STEP_ONE: 'ftu_create_shipment_step_one',
  STEP_TWO: 'ftu_create_shipment_step_two',
  STEP_THREE: 'ftu_create_shipment_step_three',
  STEP_FOUR: 'ftu_create_shipment_step_four',
};

export const CLASSNAME_FTU_MY_SHIPMENTS = {
  STEP_ONE: 'ftu_my_shipments_step_one',
};

export const CLASSNAME_FTU_BILLING = {
  STEP_ONE: 'ftu_billing_step_one',
  STEP_TWO: 'ftu_billing_step_two',
  STEP_THREE: 'ftu_billing_step_three',
};

export const CLASSNAME_FTU_MY_SHIPMENTS_EXPORT_LABELS = {
  STEP_ONE: 'export_labels_actions_bar',
};
