import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from '@user/containers/PrivateRoute';

import ROUTES from '@app/services/routes';
import { BrandLoader } from '@appscore/web-components';
import { OperationalState } from '@app/utils/constants/partner.constants';
import useInitialData from '@app/hooks/useInitialData';
const SignUp = lazy(() => import('@user/containers/Login/Signup'));
const SelectPartner = lazy(() => import('@user/containers/SelectPartner'));
const SendOrder = lazy(() => import('@send-order/SendOrder/Loadable'));
const OrdersMultiple = lazy(() => import('@orders-multiple/Loadable'));
const AddressEditor = lazy(() => import('@user/containers/MyAddresses/Editor'));
const MyAccount = lazy(() => import('@user/containers/MyAccount/Loadable'));
const MyShipping = lazy(() => import('@my-shipping/pages/MyShipping/Loadable'));
const HelpCenterList = lazy(() => import('@modules/help-center/pages/HelpCenterList/Loadable'));
const HelpCenterDetail = lazy(() => import('@modules/help-center/pages/HelpCenterDetail/Loadable'));
const Billing = lazy(() => import('@modules/billing/pages/Billing/Loadable'));
const Support = lazy(() => import('@support/containers/Support/Loadable'));
const SessionExpired = lazy(() => import('@home/containers/SessionExpired/Loadable'));
const Faqs = lazy(() => import('@global/containers/Faqs/Loadable'));
const TermsAndConditions = lazy(() => import('@home/containers/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('@home/containers/PrivacyPolicy'));
const Login = lazy(() => import('@user/containers/Login'));
const Integrations = lazy(() => import('@integrations/pages/Integrations'));
const FAQ = lazy(() => import('@home/containers/Landing/FAQ'));

export default function PrivateRoutes({
  isSupervisor,
  setGenericError,
  genericError,
  totalPartners,
  showApiCredentialsFlag,
  setCareCenterInstances,
  careCenterInstances,
  userCanCreateOrder,
  userCanCreateOrdersInBulk,
  applyRolloutDHHelpCenter,
  setContactCenterInstances,
  contactCenterInstances,
  isClassicUser,
  pathname,
  isUserLogged,
}) {
  const { initialData } = useInitialData();
  const partnerId = initialData.user?.partnerId;
  const partnerInPendingState =
    initialData.user?.partnerOperationalState === OperationalState.PENDING;

  const mustGoToSignup = isUserLogged && totalPartners === 0 && pathname !== ROUTES.SIGNUP;

  const isPartnerSelected = Boolean(totalPartners === 1 || (totalPartners > 1 && partnerId));

  // The user has partners and can create orders, so must go to create order
  const canGoToCreateOrder =
    !partnerInPendingState &&
    userCanCreateOrder &&
    isPartnerSelected &&
    pathname === ROUTES.SELECT_PARTNER;

  // The user has partners and can create orders in bulk, so must go to create orders in bulk
  const canGoToCreateOrdersInBulk = userCanCreateOrdersInBulk && userCanCreateOrder;

  return (
    <Suspense fallback={<BrandLoader />}>
      <Switch>
        {mustGoToSignup && <Redirect to={ROUTES.SIGNUP} />}
        <PrivateRoute
          path={ROUTES.SIGNUP}
          exact
          setCareCenterInstances={setCareCenterInstances}
          careCenterInstances={careCenterInstances}
          render={props => <SignUp {...props} />}
          initialData={initialData}
          setGenericError={setGenericError}
        />

        <PrivateRoute
          path={ROUTES.SELECT_PARTNER}
          initialData={initialData}
          setGenericError={setGenericError}
          genericError={genericError}
          exact
          render={props => <SelectPartner {...props} />}
        />

        {canGoToCreateOrder && <Redirect to={ROUTES.CREATE_ORDER} />}
        <PrivateRoute
          path={ROUTES.CREATE_ORDER}
          initialData={initialData}
          exact
          render={props => <SendOrder {...props} />}
        />

        {canGoToCreateOrdersInBulk && (
          <PrivateRoute
            initialData={initialData}
            path={ROUTES.ORDERS_MULTIPLE}
            exact
            render={props => <OrdersMultiple {...props} />}
          />
        )}
        <PrivateRoute
          path={`${ROUTES.ADDRESS_EDIT}/:addressId?`}
          initialData={initialData}
          render={props => <AddressEditor {...props} />}
        />
        {isSupervisor && showApiCredentialsFlag && (
          <PrivateRoute
            exact
            path={`${ROUTES.CREDENTIALS}`}
            render={props => <Integrations {...props} />}
          />
        )}

        {isSupervisor && (
          <PrivateRoute
            exact
            path={`${ROUTES.BILLING}`}
            setCareCenterInstances={setCareCenterInstances}
            careCenterInstances={careCenterInstances}
            render={props => <Billing {...props} />}
            initialData={initialData}
          />
        )}

        {/* Nested Route */}
        <PrivateRoute
          path={`${ROUTES.MY_ACCOUNT.ROOT}`}
          render={props => <MyAccount {...props} />}
        />
        <Route path={ROUTES.SUPPORT} exact render={props => <Support {...props} />} />
        <Route
          path={ROUTES.HELP_CENTER}
          exact
          render={props => (
            <HelpCenterList {...props} isUserLogged={isUserLogged} initialData={initialData} />
          )}
        />
        <Route
          path={`${ROUTES.HELP_CENTER}/:categorySlug`}
          exact
          render={props => (
            <HelpCenterDetail
              {...props}
              applyRolloutDHHelpCenter={applyRolloutDHHelpCenter}
              isUserLogged={isUserLogged}
              initialData={initialData}
              setCareCenterInstances={setCareCenterInstances}
              careCenterInstances={careCenterInstances}
            />
          )}
        />
        <Route path={ROUTES.FAQ} exact render={props => <FAQ isFromFAQRoute {...props} />} />
        <Route
          path={ROUTES.FAQS}
          exact
          render={props => (
            <Faqs
              {...props}
              setCareCenterInstances={setCareCenterInstances}
              careCenterInstances={careCenterInstances}
            />
          )}
        />
        <PrivateRoute
          path={`${ROUTES.MY_ORDERS}:filters?`}
          setCareCenterInstances={setCareCenterInstances}
          careCenterInstances={careCenterInstances}
          setContactCenterInstances={setContactCenterInstances}
          contactCenterInstances={contactCenterInstances}
          initialData={initialData}
          totalPartners={totalPartners}
          render={props => <MyShipping {...props} />}
          exact
        />
        <Route
          path={ROUTES.SESSION_EXPIRED}
          exact
          render={props => (
            <SessionExpired setCareCenterInstances={setCareCenterInstances} {...props} />
          )}
        />
        <Route
          path={ROUTES.TERMS_AND_CONDITIONS}
          exact
          render={props => <TermsAndConditions {...props} />}
        />

        {pathname !== ROUTES.ORDERS_MULTIPLE && !isClassicUser && isPartnerSelected && (
          <Redirect
            to={{
              pathname: userCanCreateOrder ? ROUTES.CREATE_ORDER : ROUTES.MY_ORDERS,
              state: { from: pathname },
            }}
          />
        )}
        <Route
          path={ROUTES.LOGIN}
          exact
          render={props => (
            <Login genericError={genericError} setGenericError={setGenericError} {...props} />
          )}
        />
        <Route path={ROUTES.PRIVACY_POLICY} exact render={props => <PrivacyPolicy {...props} />} />
      </Switch>
    </Suspense>
  );
}
