import successAction from '@global/actions/successAction';
import failAction from '@global/actions/failAction';
import { isEmptyObject } from '@utils/object';
import { logEvent, logError } from '@logs/logger';
import { GENERIC_ERROR } from '@utils/genericError';

import getAllFlags from '@funWithFlags/selectors/getAllFlags';
import { FLAGS_UPDATED } from '../actionTypes';

const type = FLAGS_UPDATED;

const updateFlags = (newFlags) => (dispatch, getState) => {
  try {
    const state = getState();
    const cachedFlags = getAllFlags(state);

    const updatedFlags = Object.values(newFlags).reduce((result, flag) => {
      if (!cachedFlags[flag.key] || flag.variation !== cachedFlags[flag.key].variation) {
        result[flag.key] = flag;
      }

      return result;
    }, {});

    if (!isEmptyObject(updatedFlags)) {
      dispatch(
        successAction({
          type,
          payload: { flags: { ...cachedFlags, ...updatedFlags } },
        }),
      );

      logEvent(`[UPDATE_FLAGS][FLAGS_UPDATED] ${JSON.stringify(updatedFlags)}`);
    }
  } catch (error) {
    logError({
      message: `[UPDATE_FLAGS][ERROR]`,
      error,
      data: newFlags,
    });
    dispatch(failAction({ type, error: error || GENERIC_ERROR }));
  }
};

export default updateFlags;
