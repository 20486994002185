import axios from 'axios';
import ROUTES from '@services/routes';
import { INVALID_TOKEN } from '@utils/genericError';

let instance = null;

const errorResponseHandler = error => {
  try {
    const code = error?.response?.data?.code;
    const invalidTokenResponseMessages = error?.response?.data?.messages?.find(
      x => typeof x === 'string' && x.includes(INVALID_TOKEN),
    );
    const invalidTokenResponseCode = code === INVALID_TOKEN;
    const status = error?.response.status;
    if (status === 403 || invalidTokenResponseMessages || invalidTokenResponseCode) {
      window.location.replace(ROUTES.SESSION_EXPIRED);
    }
    return Promise.reject(error);
  } catch {
    return Promise.reject(error);
  }
};

const applyInterceptor = axiosInstance => {
  axiosInstance.interceptors.response.use(response => response, errorResponseHandler);
};

export const initialize = props => {
  instance = (props && axios.create(props)) || axios.create();
  instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  instance.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded';
  instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  instance.defaults.headers.delete['Access-Control-Allow-Origin'] = '*';
  instance.defaults.withCredentials = true;
  applyInterceptor(instance);
  return instance;
};

const getInstance = props => instance || initialize(props);

export default getInstance;
