import { createSelector } from 'reselect';
import { IS_SENDING_ORDERS } from '@orders-bulk/constants';
import getPropFromModule from './getPropFromModule';

const getIsSendingOrder = createSelector(
  state => getPropFromModule(state, IS_SENDING_ORDERS),
  isSendingOrders => isSendingOrders,
);

export default getIsSendingOrder;
