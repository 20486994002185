import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { QueryString } from '@utils/query-string';

import getShowLandingPageFlag from '@funWithFlags/selectors/getShowLandingPageFlag';
import showApiCredentialsFlag from '@funWithFlags/selectors/getShowApiCredentialsFlag';
import isSupervisor from '@user/selectors/getIsSupervisor';
import getIsClassicUser from '@user/selectors/getIsClassicUser';
import updateUserLogged from '@user/actions/doUpdateUserLogged';
import saveSelectedPartner from '@global/actions/savePartnerSelected';
import getSelectedPartner from '@global/selectors/getSelectedPartner';
import getTotalPartners from '@user/selectors/getTotalPartners';
import getIsUserLogged from '@user/selectors/getIsUserLogged';
import getRolloutDHHelpCenterFlag from '@funWithFlags/selectors/getRolloutDHHelpCenterFlag';
import getPartnerInfo from '@app/services/PartnerService/getPartnerInfo';
import CountrySelection from '@home/containers/CountrySelection/Loadable';
import RebrandingHeader from '@global/components/RebrandingHeader';
import ROUTES, { isTrackingPage, isFAQPage } from '@services/routes';

import getUserCanCreateOrder from '@user/selectors/getUserCanCreateOrder';
import getUserLoggedID from '@user/selectors/getUserLoggedID';
import getUserLogged from '@user/selectors/getUserLogged';
import getIsSendingOrders from '@orders-bulk/selectors/getIsSendingOrders';
import getSelectedCountry from '@global/selectors/getSelectedCountry';
import getIsValidatingOrders from '@orders-bulk/selectors/getOrdersAreValidating';
import { remove, get, save } from '@services/sessionStorage';
import { SessionKeys } from '@utils/constants/storage.constant';
import { loadQualtrics, isValidDateForQualtrics } from '@utils/scripts/loadQualtrics';
import { OperationalState } from '@utils/constants/partner.constants';
import useInitialData from './hooks/useInitialData';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';

const RouterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: ${props => (props.landing ? 'auto' : '100vh')};
`;

const userIsDoingLoginOrRegister = ({ pathname }) =>
  pathname === ROUTES.LOGIN || pathname === ROUTES.REGISTRY || pathname === ROUTES.SIGNUP;

// Si vienen los params corerctos (appKey && consumerKey && ecommerce), guarda una key en session storage para que cuando el usuario sea enterprise
// (sino lo es) redirija a /credentials
const integrationFlow = ({ location: { pathname, search }, isClassicUser }) => {
  const params = new QueryString(search);
  const appKey = params.get('appKey');
  const consumerKey = params.get('consumerKey');
  const ecommerce = params.get('ecommerce');

  const hasValidCredentialsParams = Boolean(appKey && consumerKey && ecommerce);
  if (hasValidCredentialsParams && isClassicUser) {
    save(SessionKeys.GO_TO_AFTER_LOGIN, pathname + search);
  }
};

// Guarda la ruta actual para redirigir al usuario después del login, solo si es una ruta protegida.
const checkAndSaveGoAfterLogin = ({ location: { pathname, search }, isUserLogged }) => {
  const protectedPathnameStart = [
    ROUTES.CREATE_ORDER,
    ROUTES.ORDERS_MULTIPLE,
    ROUTES.MY_ORDERS,
    ROUTES.CREDENTIALS,
    ROUTES.BILLING,
    ROUTES.MY_ACCOUNT.ROOT,
  ];

  const isValidPathname = protectedPathnameStart.some(
    value => pathname?.startsWith(value) || pathname?.indexOf(value) === 0,
  );

  if (!isUserLogged && !userIsDoingLoginOrRegister({ pathname }) && isValidPathname) {
    save(SessionKeys.GO_TO_AFTER_LOGIN, pathname + search);
  }
};

// si existe una ruta guardada para redirig, redirige x unica vez, y borra la key guardada (siempre que haya un
// user logeado, y este no sea classic)
// eslint-disable-next-line consistent-return
const redirectAfterLogin = ({ isUserLogged, isClassicUser, pathname }) => {
  const goToAfterLogin = get(SessionKeys.GO_TO_AFTER_LOGIN);
  if (isUserLogged) {
    if (!isClassicUser && goToAfterLogin && pathname !== goToAfterLogin) {
      remove(SessionKeys.GO_TO_AFTER_LOGIN);
      return <Redirect to={goToAfterLogin} />;
    }
  }
};

let redirect;

function AppRouter({
  userCanCreateOrder,
  isSendingOrders,
  isValidatingOrders,
  userLoggedId,
  showLandingPageFlag,
  selectedCountry,
  setCareCenterInstances,
  careCenterInstances,
  setContactCenterInstances,
  contactCenterInstances,
  isUserLogged,
  totalPartners,
  applyRolloutDHHelpCenter,
  isClassicUser,
  location,
  selectedPartner,
  doSelectedPartner,
}) {
  const { initialData } = useInitialData();
  const partnerId = initialData.user?.partnerId;
  const partnerInPendingState =
    initialData.user?.partnerOperationalState === OperationalState.PENDING;
  const [userCanCreateOrdersInBulk, setUserCanCreateOrdersInBulk] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const { pathname } = location;
  useEffect(() => {
    integrationFlow({ location, isUserLogged, isClassicUser });
    checkAndSaveGoAfterLogin({ location, isUserLogged });
    redirect = redirectAfterLogin({ isUserLogged, isClassicUser, pathname });
  }, [location]);

  useEffect(() => {
    if (isUserLogged && isValidDateForQualtrics()) loadQualtrics(); // Load quatrics poll for logged users after january 2 monday
  }, [isUserLogged]);

  useQuery(`AppRouter.getPartnerInfo-${partnerId}`, getPartnerInfo, {
    enabled: isUserLogged && !isClassicUser && !partnerInPendingState && Boolean(partnerId),
    retry: false,
    onSuccess: response => {
      if (response.data) {
        const { data } = response.data;
        setUserCanCreateOrdersInBulk(data.allowBulkOrders);
        if (!data.allowBulkOrders && pathname === ROUTES.ORDERS_MULTIPLE) {
          redirect = <Redirect to={ROUTES.CREATE_ORDER} />;
        }
      }
    },
  });

  if (!selectedCountry) {
    return <CountrySelection />;
  }
  if (redirect) return redirect;

  const isRouteLoginOrRegistry = userIsDoingLoginOrRegister({ pathname });

  if (partnerId && partnerId !== selectedPartner && initialData.user?.selectedPartner) {
    doSelectedPartner(initialData.user?.selectedPartner);
  }

  const includeHeader =
    !showLandingPageFlag ||
    (pathname !== ROUTES.HOME &&
      !isRouteLoginOrRegistry &&
      !isTrackingPage(pathname) &&
      !isFAQPage(pathname));

  return (
    <RouterContainer landing={!userLoggedId && !isRouteLoginOrRegistry}>
      {includeHeader && (
        <RebrandingHeader
          userCanCreateOrdersInBulk={userCanCreateOrdersInBulk && userCanCreateOrder}
          setCareCenterInstances={setCareCenterInstances}
          disabled={isSendingOrders || isValidatingOrders}
        />
      )}

      {!isUserLogged ? (
        <PublicRoutes genericError={genericError} setGenericError={setGenericError} />
      ) : (
        <PrivateRoutes
          isSupervisor={isSupervisor}
          isClassicUser={isClassicUser}
          setGenericError={setGenericError}
          genericError={genericError}
          totalPartners={totalPartners}
          showApiCredentialsFlag={showApiCredentialsFlag}
          setCareCenterInstances={setCareCenterInstances}
          careCenterInstances={careCenterInstances}
          userCanCreateOrder={userCanCreateOrder}
          userCanCreateOrdersInBulk={userCanCreateOrdersInBulk}
          applyRolloutDHHelpCenter={applyRolloutDHHelpCenter}
          setContactCenterInstances={setContactCenterInstances}
          contactCenterInstances={contactCenterInstances}
          pathname={pathname}
          isUserLogged={isUserLogged}
        />
      )}
    </RouterContainer>
  );
}

AppRouter.propTypes = {
  isClassicUser: PropTypes.bool.isRequired,
  isUserLogged: PropTypes.bool.isRequired,
  userCanCreateOrder: PropTypes.bool,
  isSendingOrders: PropTypes.bool,
  isValidatingOrders: PropTypes.bool,
  showLandingPageFlag: PropTypes.bool,
  userLoggedId: PropTypes.number,
  selectedCountry: PropTypes.object,
  setCareCenterInstances: PropTypes.func,
  careCenterInstances: PropTypes.array,
  setContactCenterInstances: PropTypes.func,
  contactCenterInstances: PropTypes.array,
};
// export default Router;
const mapStateToProps = createStructuredSelector({
  userCanCreateOrder: state => getUserCanCreateOrder(state),
  isSendingOrders: getIsSendingOrders,
  isClassicUser: getIsClassicUser,
  isValidatingOrders: getIsValidatingOrders,
  userLoggedId: getUserLoggedID,
  showLandingPageFlag: getShowLandingPageFlag,
  selectedCountry: getSelectedCountry,
  userLogged: getUserLogged,
  applyRolloutDHHelpCenter: getRolloutDHHelpCenterFlag,
  isUserLogged: getIsUserLogged,
  totalPartners: getTotalPartners,
  selectedPartner: getSelectedPartner,
});

const mapDispatchToProps = {
  doSelectedPartner: saveSelectedPartner,
  doUpdateUserLogged: updateUserLogged,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRouter));
