import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import downArrow from '@assets/icons/down-arrow.svg';
import ROUTES from '@services/routes';
import logout from '@assets/icons/logout-new.svg';
import help from '@assets/icons/help-new.svg';
import HeaderLinkWithIcon from '@global/components/HeaderLinkWithIcon';
import { CLASSNAME_FTU_MY_SHIPMENTS } from '@utils/constants/tour.constant';
import Pill from '@components/Pill';
import { LOGISTIC_MODE } from '@orders-bulk/actions/utils/constants';
import PartnerSelector from '@global/components/PartnerSelector';
import ClassicUserTooltip from './ClassicUserTooltip';
import MenuContainer from './styled-components/MenuContainer';
import NavLinksContainer from './styled-components/NavLinksContainer';
import MobileNavContainer from './MobileNavContainer';
import HeaderLink, { HeaderLinkContainer } from './styled-components/HeaderLink';
import {
  HeaderDropdown,
  DropdownLabelContainer,
  DropdownItemsContainer,
} from './styled-components/HeaderDropdown';
import Pipe from './styled-components/Pipe';
import IsNewIndicator from './styled-components/IsNewIndicator';
import messages from './messages';
import { DropdownContainer } from './styled-components/DropdownContainer';

import getPrivateLinks from './privateLinks';
import { registerClick } from './utils/pills';

const NavLinks = ({
  isUserLogged,
  pushPage,
  location,
  doLogout,
  trackHeaderClick,
  intl: { formatMessage },
  userCanCreateOrder,
  userCanCreateOrdersInBulk,
  isValidatingOrders,
  isSendingOrders,
  setCareCenterInstances,
  isSupervisor,
  showApiCredentialsFlag,
  isClassicUser,
  stopNavigation,
  updateRouteSelected,
  userLogged,
  totalPartners,
  selectedPartner,
}) => {
  const [privateLinks, setPrivateLinks] = useState([]);
  const disabled = isValidatingOrders || isSendingOrders;
  useEffect(() => {
    const isCrossDocking = userLogged?.logisticMode === LOGISTIC_MODE.CROSS_DOCKING;
    const links = getPrivateLinks({
      userCanCreateOrder,
      userCanCreateOrdersInBulk,
      isSupervisor,
      showApiCredentialsFlag,
      isClassicUser,
      isCrossDocking,
    });
    setPrivateLinks(links);
  }, [userCanCreateOrder, userCanCreateOrdersInBulk, isSupervisor]);

  const showPartnerSelector = selectedPartner && totalPartners > 1;

  const getHeaderLink = (link, isDropdown = false, isNew = false) => {
    const isCreateShipment = location.pathname === ROUTES.CREATE_ORDER;
    const isMyShipments = link.route === ROUTES.MY_ORDERS;

    return (
      <HeaderLinkContainer
        key={link.route + isDropdown}
        isDropdown={isDropdown}
        isSelected={location.pathname === link.route}
        className={
          isCreateShipment && isMyShipments ? CLASSNAME_FTU_MY_SHIPMENTS.STEP_ONE : undefined
        }
        onClick={() => {
          if (!disabled) {
            if (stopNavigation && link.route !== ROUTES.ORDERS_MULTIPLE) {
              updateRouteSelected(link.route);
              return;
            }

            trackHeaderClick({
              event: link.trackEvent,
              pathname: location.pathname,
            });
            registerClick(link.pillId);
            pushPage(link.route);
          }
        }}
      >
        <HeaderLink key={link.route} isDropdown={isDropdown} disabled={disabled}>
          {formatMessage(link.label)}
        </HeaderLink>

        {isDropdown && isNew && <Pill text="Nuevo" />}
      </HeaderLinkContainer>
    );
  };

  const getDropdownLinks = dropdownItem => {
    const isSelected = dropdownItem.items.some(x => x.route === location.pathname);

    const thereIsNew = dropdownItem.items.some(x => x.isNew);
    return (
      <DropdownContainer key={`dropdown-container-header${formatMessage(dropdownItem.label)}`}>
        <HeaderDropdown>
          <DropdownLabelContainer isSelected={isSelected}>
            {thereIsNew && <IsNewIndicator />}
            <span>{formatMessage(dropdownItem.label)}</span>
            <img src={downArrow} />
            <DropdownItemsContainer>
              {dropdownItem.items.map(link => (
                <li key={link.trackEvent}>{getHeaderLink(link, true, link.isNew)}</li>
              ))}
            </DropdownItemsContainer>
          </DropdownLabelContainer>
        </HeaderDropdown>
        {dropdownItem.tooltip && <ClassicUserTooltip text={dropdownItem.tooltip} />}
      </DropdownContainer>
    );
  };

  const linksToRender = privateLinks.map(link => {
    if (link.isDropdown) {
      return getDropdownLinks(link);
    }
    return getHeaderLink(link);
  });

  return (
    <MenuContainer>
      <NavLinksContainer>
        {showPartnerSelector && <PartnerSelector />}
        {isUserLogged && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {linksToRender}
            <Pipe />
          </div>
        )}

        <HeaderLinkWithIcon
          disabled={disabled}
          linkIcon={help}
          label={formatMessage(messages.supportCenter)}
          onClick={() => {
            if (stopNavigation && location.pathname !== ROUTES.ORDERS_MULTIPLE) {
              updateRouteSelected(ROUTES.HELP_CENTER);
              return;
            }
            trackHeaderClick({
              event: 'FAQS',
              pathname: location.pathname,
            });
            pushPage(ROUTES.HELP_CENTER);
          }}
        />
        {isUserLogged && (
          <HeaderLinkWithIcon
            disabled={disabled}
            linkIcon={logout}
            label={formatMessage(messages.logout)}
            onClick={() => {
              trackHeaderClick({
                event: 'LOGOUT',
                pathname: location.pathname,
              });
              setCareCenterInstances([]);
              doLogout();
            }}
          />
        )}
      </NavLinksContainer>
      <MobileNavContainer
        disabled={disabled}
        location={location}
        pushPage={pushPage}
        links={privateLinks}
        isUserLogged={isUserLogged}
        doLogout={doLogout}
        trackHeaderClick={trackHeaderClick}
        selectedPartner
        showPartnerSelector={showPartnerSelector}
      />
    </MenuContainer>
  );
};

NavLinks.propTypes = {
  isUserLogged: PropTypes.bool,
  userCanCreateOrder: PropTypes.bool,
  userCanCreateOrdersInBulk: PropTypes.bool,
  isSupervisor: PropTypes.bool.isRequired,
  showApiCredentialsFlag: PropTypes.bool.isRequired,
  pushPage: PropTypes.func,
  location: PropTypes.object,
  doLogout: PropTypes.func,
  trackHeaderClick: PropTypes.func,
  isValidatingOrders: PropTypes.bool,
  isSendingOrders: PropTypes.bool,
  setCareCenterInstances: PropTypes.func,
  isClassicUser: PropTypes.bool,
  stopNavigation: PropTypes.bool,
  updateRouteSelected: PropTypes.func,
  userLogged: PropTypes.object,
};

export default injectIntl(NavLinks);
