import { GENERIC_ERROR } from '@app/utils/genericError';
import axiosInstance from '@services/axios';
import { getPartnersURL } from '../URL';
import ServiceResponse from '../serviceResponse';

async function fetchPartners(partnerName) {
  try {
    const axios = axiosInstance();
    const responseFromServer = await axios.get(getPartnersURL(partnerName));
    return new ServiceResponse({
      ...responseFromServer,
      data: convertDataToSelectPartners(responseFromServer.data.data),
    });
  } catch (error) {
    return new ServiceResponse({ error: error.response || GENERIC_ERROR });
  }
}
const convertDataToSelectPartners = array =>
  array?.map(obj => ({
    value: obj.id,
    label: obj.name,
  }));

export default fetchPartners;
