import styled from 'styled-components';

const Logo = styled.img`
  min-width: 180px;
  ${props => props.withMargin && ` margin-bottom: 30px;`}}
  ${props => props.large && `width: 250px;`}}
  ${props => props.flexStart && `place-self: flex-start;`}}
`;

export default Logo;
