const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  SELECT_PARTNER: '/partner/select',
  REGISTRY: '/registry',
  SIGNUP: '/login/signup',
  ABOUT_US: '/about-us',
  SUPPORT: '/support',
  MY_ORDERS: '/orders',
  CREATE_ORDER: '/orders/create',
  BILLING: '/billing',

  MY_ACCOUNT: {
    // Root de la ruta
    ROOT: '/my-account',

    // Seccion mis direcciones
    MY_ADDRESSES: '/addresses',

    // Seccion mis datos
    MY_PROFILE: '/profile',
  },
  // Editor de direcciones y sucursales
  ADDRESS_EDIT: '/address',

  // Seccion integraciones, classic y enterprise
  CREDENTIALS: '/credentials',

  ONBOARDING: '/onboarding',
  ERROR: '/error',
  SESSION_EXPIRED: '/session-expired',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  RESET_PASSWORD: '/resetPassword',
  PRIVACY_POLICY: '/privacy-policy',
  ORDERS_IN_BULK: '/orders/create/bulk',
  ORDERS_MULTIPLE: '/orders/create/multiple',
  FAQ: '/FAQ',
  FAQS: '/frequently-asked-questions',
  HELP_CENTER: '/help-center',
  HELP_CENTER_POD:
    '/help-center/create-shipments#que-es-y-como-funciona-el-codigo-para-validar-la-entrega',
};

export const isTrackingPage = route => {
  const trackingRegex = /\/tracking/;
  if (!route) return false;
  return trackingRegex.test(route);
};
export const isFAQPage = route => {
  const faqRegex = /\/FAQ/;
  if (!route) return false;
  return faqRegex.test(route);
};

export default ROUTES;
