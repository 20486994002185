import styled from 'styled-components';

const HeaderLink = styled.a`
  color: ${props => props.theme.colors.White};
  align-items: center;
  font-weight: ${props => (props.isDropdown ? 'normal' : 'bold')};
  white-space: nowrap;
  display: flex;
  text-align: center;
  margin-right: 5px;
  font-size: 0.9em;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  padding: ${props => (props.isDropdown ? '12px 0px 12px 0px' : '24px')};
`;

export const HeaderLinkContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 4px 0px 4px;
  ${props =>
    props.isSelected &&
    `border-bottom: 3px solid ${props.theme.colors.White};
    border-right: ${props.isMobile && '3px solid'};
    border-left: ${props.isMobile && '3px solid'};
    background-color: ${props.isDropdown ? 'rgb(238, 238, 238)' : props.theme.colors.Main};
  `}
`;

export default HeaderLink;
