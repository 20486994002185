import getIsFromLogout from '@user/selectors/getIsFromLogout';
import getShowLandingPageFlag from '@funWithFlags/selectors/getShowLandingPageFlag';
import { TRACKING_NAMES } from '@tracking/constants';
import track from '@tracking/index';
import { logError } from '@logs/logger';

const windowLocation = window.location;

export const loginStarted = () => (_, getState) => {
  const state = getState();
  const fromLogout = getIsFromLogout(state);
  const EVENT_NAME = `login.started`;

  try {
    const payload = {
      pageType: TRACKING_NAMES.LOGIN,
      pageUrlPath: windowLocation.pathname,
      documentLocation: windowLocation.href,
      landingOrigin: fromLogout ? 'logout' : 'new visit',
    };

    track(EVENT_NAME, payload);
  } catch (error) {
    logError({ message: `Error on Tracking (GTM) : ${EVENT_NAME}`, error });
  }
};

export const landingPageLoaded = () => (_, getState) => {
  const state = getState();
  const fromLogout = getIsFromLogout(state);
  const EVENT_NAME = `landing_page.loaded`;

  try {
    const payload = {
      pageType: TRACKING_NAMES.LANDING,
      pageUrlPath: windowLocation.pathname,
      documentLocation: windowLocation.href,
      landingOrigin: fromLogout ? 'logout' : 'new visit',
    };

    track(EVENT_NAME, payload);
  } catch (error) {
    logError({ message: `Error on Tracking (GTM) : ${EVENT_NAME}`, error });
  }
};

export const loginClicked = () => (_, getState) => {
  const state = getState();
  const showLanding = getShowLandingPageFlag(state);
  const EVENT_NAME = `login.clicked`;

  try {
    const payload = {
      pageType: showLanding ? TRACKING_NAMES.LANDING : TRACKING_NAMES.LOGIN,
      pageUrlPath: windowLocation.pathname,
      documentLocation: windowLocation.href,
      time: new Date(Date.now()).toLocaleString(),
    };

    track(EVENT_NAME, payload);
  } catch (error) {
    logError({ message: `Error on Tracking (GTM) : ${EVENT_NAME}`, error });
  }
};

export const loginSucceeded = user => (_, getState) => {
  const state = getState();
  const showLanding = getShowLandingPageFlag(state);
  const EVENT_NAME = `login.succeeded`;

  try {
    const payload = {
      userId: user.id,
      userType: user.type,
      pageType: showLanding ? TRACKING_NAMES.LANDING : TRACKING_NAMES.LOGIN,
      pageUrlPath: windowLocation.pathname,
      documentLocation: windowLocation.href,
      companyName: user.branchParentName,
      time: new Date(Date.now()).toLocaleString(),
    };

    track(EVENT_NAME, payload);
  } catch (error) {
    logError({ message: `Error on Tracking (GTM) : ${EVENT_NAME}`, error });
  }
};

export const loginFailed = () => (_, getState) => {
  const state = getState();
  const showLanding = getShowLandingPageFlag(state);
  const EVENT_NAME = `login.failed`;

  try {
    const payload = {
      pageType: showLanding ? TRACKING_NAMES.LANDING : TRACKING_NAMES.LOGIN,
      pageUrlPath: windowLocation.pathname,
      documentLocation: windowLocation.href,
      time: new Date(Date.now()).toLocaleString(),
    };

    track(EVENT_NAME, payload);
  } catch (error) {
    logError({ message: `Error on Tracking (GTM) : ${EVENT_NAME}`, error });
  }
};
