export const MODULE_NAME = 'fwf';
export const FWF_FLAGS = 'flags';

export const FLAGS_COMPARE_FUNCTIONS = {
  BOOLEAN: (oldFlag, newFlag) => oldFlag.variation !== newFlag.variation,
};

export const FLAGS = {
  ORDER_CREATION_DISABLED: {
    KEY: 'order-creation-disabled',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  UX_REBRANDING: {
    KEY: 'ux-rebranding',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOW_LANDING_PAGE: {
    KEY: 'show-landing-page',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  SHOW_API_CREDENTIALS: {
    KEY: 'show-api-credentials',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  IS_ORDERING_FLOW_ENABLED: {
    KEY: 'is-ordering-flow-enabled',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  IS_OLD_CLASSIC_USER: {
    KEY: 'is-classic-user',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  INTEGRATED_PARTNER: {
    KEY: 'integrated-partner',
    COMPARE_FN: FLAGS_COMPARE_FUNCTIONS.BOOLEAN,
  },
  ROLLOUT_DH_HELP_CENTER: {
    KEY: 'rollout-dh-help-center-flow-for-courier-business',
  },
};

export const FLAGS_KEYS = Object.values(FLAGS).map(x => x.KEY);
