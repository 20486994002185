import ROUTES from '@services/routes';
import messages from './messages';
import { registerNewPill, isNew } from './utils/pills';

const getPrivateLinks = ({
  userCanCreateOrder,
  userCanCreateOrdersInBulk,
  isSupervisor,
  showApiCredentialsFlag,
  isClassicUser,
  isCrossDocking,
}) => {
  const links = [];
  const items = [];
  const routeBulk = isCrossDocking ? ROUTES.ORDERS_IN_BULK : ROUTES.ORDERS_MULTIPLE;

  registerNewPill('myProfile', new Date('2021-12-17'));

  if (userCanCreateOrder) {
    items.push({
      label: messages.createCommonOrder,
      trackEvent: 'CREATE_ORDER',
      route: ROUTES.CREATE_ORDER,
    });
  }
  if (userCanCreateOrdersInBulk) {
    items.push({
      label: messages.ordersBulk,
      trackEvent: 'ORDERS_IN_BULK',
      route: routeBulk,
    });
  }

  if (userCanCreateOrder || userCanCreateOrdersInBulk) {
    links.push({
      isDropdown: userCanCreateOrder && userCanCreateOrdersInBulk,
      label: !userCanCreateOrder ? messages.ordersBulk : messages.createOrder,
      route: !userCanCreateOrder ? routeBulk : ROUTES.CREATE_ORDER,
      items,
    });
  }

  const privateLinks = [
    {
      label: messages.myOrders,
      trackEvent: 'MY_ORDERS',
      route: ROUTES.MY_ORDERS,
    },
  ];

  /*
  Si es enterprise:
    - check si es supervisor
    - check si tiene la flag
  Si es classic:
    - nada

  Al if entra si es classic, o si es supervisor y tiene la flag
  */
  if (isClassicUser || (isSupervisor && showApiCredentialsFlag)) {
    privateLinks.push({
      label: messages.apiCredentials,
      trackEvent: 'CREDENTIALS',
      route: `${ROUTES.CREDENTIALS}`,
    });
  }

  const myAccount = {
    isDropdown: true,
    label: messages.myAccount2,
    trackEvent: '',
    route: 'my_account',
    items: [],
    tooltip: isClassicUser ? messages.integrationsTooltip.defaultMessage : '',
  };

  myAccount.items.push({
    label: messages.myProfile,
    trackEvent: 'MY_PROFILE',
    route: `${ROUTES.MY_ACCOUNT.ROOT}${ROUTES.MY_ACCOUNT.MY_PROFILE}`,
    pillId: 'myProfile',
    isNew: isNew('myProfile'),
  });

  if (isSupervisor) {
    myAccount.items.push({
      label: messages.billing,
      trackEvent: 'BILLING',
      route: `${ROUTES.BILLING}`,
    });
  }

  if (!isClassicUser) {
    myAccount.items.push({
      label: messages.myAddresses,
      trackEvent: 'MY_ADDRESSES',
      route: `${ROUTES.MY_ACCOUNT.ROOT}${ROUTES.MY_ACCOUNT.MY_ADDRESSES}`,
    });
  }
  privateLinks.push(myAccount);

  privateLinks.forEach(link => {
    links.push(link);
  });
  return links;
};

export default getPrivateLinks;
