import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from '@services/axios';
import { setInstance as setTrackerInstance } from '@tracking';
import App from './App';
import configureStore from './configureStore';
import onGoogleMapLoaded from './modules/location/actions/onGoogleMapLoaded';
import initApp from './modules/global/actions/initApp';
import loadGoogleMaps from './utils/scripts/loadGoogleMaps';
import { setEnv } from './utils/environment';
import { initializeInstance, updateUser as updateFWFUser } from './modules/fwf/instance';
import updateFlags from './modules/fwf/actions/updateFlags';
import initSentry, { sentryConfig, updateCountry, updateUser } from './logs/logger';
const store = configureStore({
  initialState: {},
  axios: axios(),
});
const onFlagsUpdate = flags => {
  store.dispatch(updateFlags(flags));
};
const loadApp = async ({ initialData, context, setIsAppLoaded }) => {
  const { googleMapsKey, country, user, sessionId } = initialData;
  store.dispatch(initApp({ initialData }));
  loadGoogleMaps(googleMapsKey);
  await initSentry(sentryConfig(context.environment));
  updateUser(sessionId, { id: user?.id, email: user?.email });
  updateCountry(country.shortName);
  const fwfContext = {
    sessionId,
    contextData: {
      country: country.shortName,
    },
    userData: { userId: user?.id, userEmail: user?.email },
  };
  if (user?.partnerId) {
    fwfContext.contextData = {
      ...fwfContext.contextData,
      partnerId: user.partnerId,
    };
  }
  initializeInstance({ sessionId }, onFlagsUpdate)
    .then(instance => {
      updateFWFUser({ ...fwfContext, instance }).then(() => {
        setIsAppLoaded(true);
      });
    })
    .catch(() => {
      setIsAppLoaded(true);
    });
};

export default function Root({ initialData, tracker, messages, name, context, ...props }) {
  setEnv({ environment: context.environment });

  setTrackerInstance(tracker, initialData);
  const [isAppLoaded, setIsAppLoaded] = React.useState(false);
  useEffect(() => {
    window.onMapLoad = () => {
      store.dispatch(onGoogleMapLoaded());
      delete window.onMapLoad;
    };
    loadApp({ initialData, context, setIsAppLoaded });
  }, []);

  return (
    <App
      initialData={initialData}
      isAppLoaded={isAppLoaded}
      context={context}
      messages={messages}
      name={name}
      {...props}
      store={store}
    />
  );
}

Root.propTypes = {
  tracker: PropTypes.object,
  messages: PropTypes.object,
  name: PropTypes.string,
  fwf: PropTypes.object,
  initialData: PropTypes.object,
  context: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    customize: PropTypes.string,
    device: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    country: PropTypes.shape({
      id: PropTypes.number.isRequired,
      currencySymbol: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      defaultCity: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    }),
  }).isRequired,
};
