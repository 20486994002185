import { createSelector } from 'reselect';
import { USER_LOGGED } from '@user/constants';

import getPropFromModule from './getPropFromModule';

const getUserLogged = createSelector(
  state => getPropFromModule(state, USER_LOGGED),
  userLogged => userLogged,
);

export default getUserLogged;
