import produce from 'immer';
import ModuleStateModel from './defaultState';
import * as modelKeys from './constants';
import * as actions from './actionTypes';

const initialState = ModuleStateModel;

// eslint-disable-next-line default-param-last
const ordersMultipleModuleReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.CHANGE_ROUTE_SELECTED: {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { route } = action?.payload;
        // eslint-disable-next-line no-param-reassign
        draft[modelKeys.ROUTE_SELECTED] = route;
        break;
      }
      case actions.CHANGE_STOP_NAVIGATION: {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const { stop } = action?.payload;
        // eslint-disable-next-line no-param-reassign
        draft[modelKeys.STOP_NAVIGATION] = stop;
        break;
      }
      default:
        break;
    }
  });

export default ordersMultipleModuleReducer;
