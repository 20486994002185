import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getIsUserLogged from '@user/selectors/getIsUserLogged';
import getUserType from '@user/selectors/getUserType';
import getUserCanCreateOrder from '@user/selectors/getUserCanCreateOrder';

import PrivateRoute from './PrivateRoute';

const mapStateToProps = createStructuredSelector({
  isUserLogged: getIsUserLogged,
  userType: getUserType,
  userCanCreateOrder: state => getUserCanCreateOrder(state),
});

export default connect(mapStateToProps, null)(PrivateRoute);
