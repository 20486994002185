import { APP_INIT } from '@global/actionTypes';
import { DO_LOGIN } from '@user/actionTypes';
import successAction from '@global/actions/successAction';
import failAction from '@global/actions/failAction';
import { isEmptyObject } from '@utils/object';
import { GENERIC_ERROR } from '@utils/genericError';
import ROUTES from '@services/routes';
import dropCareCenterInstances from '@services/hooks/dropInstances';
import { trackUserIdentify } from '@tracking/actions/trackUserIdentyfy';
import { logError } from '@logs/logger';

const type = APP_INIT;
const loginType = DO_LOGIN;

const initApp =
  ({ initialData }) =>
  async dispatch => {
    try {
      dispatch(
        successAction({
          type,
          payload: initialData,
        }),
      );
      if (
        initialData.user &&
        !isEmptyObject(initialData.user) &&
        window.location.pathname !== ROUTES.SESSION_EXPIRED
      ) {
        await dispatch(
          successAction({
            type: loginType,
            payload: initialData.user,
          }),
        );
        dispatch(trackUserIdentify(initialData.user));
      } else {
        dropCareCenterInstances();
      }
    } catch (e) {
      dispatch(failAction({ type, error: e || GENERIC_ERROR }));
      logError({
        message: `Exception on Action (dispatch) Dispatching failure action: ${type}`,
        error: e || GENERIC_ERROR,
      });
    }
  };

export default initApp;
