import styled from 'styled-components';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 520px;
  padding-left: 10vw;
  justify-content: center;
  margin-top: -10vh;
`;

export default TextContainer;
