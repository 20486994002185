import getUserLogged from '@user/selectors/getUserLogged';
import getSelectedCountryShortName from '@global/selectors/getSelectedCountryShortName';
import track from '@tracking/index';
import { logError } from '@logs/logger';
import ROUTES from '@services/routes';
import { trackRoutesNames } from '../constants';

const windowLocation = window.location;

export const trackHeaderClick = data => (dispatch, getState) => {
  const EVENT_NAME = `header.clicked`;
  try {
    const { pathname, event } = data;
    const state = getState();
    const loggedUser = getUserLogged(state);
    const countryShortName = getSelectedCountryShortName(state);
    const payload = {
      userId: loggedUser.id,
      partnerId: loggedUser.partnerId,
      countryShortName,
      headerOrigin: getOrigin(pathname),
      headerDestination: event,
      time: new Date(Date.now()).toLocaleString(),
    };
    track(EVENT_NAME, payload);
  } catch (error) {
    logError({ message: `Error on Tracking (GTM) : ${EVENT_NAME}`, error });
  }
};

const getOrigin = pathname => {
  if (pathname.startsWith(ROUTES.MY_ACCOUNT.ROOT)) {
    return Object.keys(ROUTES.MY_ACCOUNT).find(
      x => `${ROUTES.MY_ACCOUNT.ROOT}${ROUTES.MY_ACCOUNT[x]}` === pathname,
    );
  }
  return Object.keys(ROUTES).find(x => ROUTES[x] === pathname);
};

export const trackSupport = () => (dispatch, getState) => {
  const EVENT_NAME = `online_support.clicked`;
  const state = getState();
  const loggedUser = getUserLogged(state);

  try {
    const payload = {
      userId: loggedUser.id,
      userType: loggedUser.type,
      pageType: trackRoutesNames[windowLocation.pathname],
      pageUrlPath: windowLocation.pathname,
      documentLocation: windowLocation.href,
      companyName: loggedUser.branchParentName,
      supportOrigin: loggedUser.type ? 'header' : 'login',
      time: new Date(Date.now()).toLocaleString(),
    };

    track(EVENT_NAME, payload);
  } catch (error) {
    logError({ message: `Error on Tracking (GTM) : ${EVENT_NAME}`, error });
  }
};

export const trackLogout = data => (dispatch, getState) => {
  const EVENT_NAME = 'logout.submitted';
  const state = getState();
  const loggedUser = getUserLogged(state);

  try {
    const payload = {
      userId: loggedUser.id,
      userType: loggedUser.type,
      pageType: trackRoutesNames[windowLocation.pathname],
      pageUrlPath: windowLocation.pathname,
      documentLocation: windowLocation.href,
      companyName: loggedUser.branchParentName,
      logOutOrigin: trackRoutesNames[data.logOutOrigin],
      time: new Date(Date.now()).toLocaleString(),
    };

    track(EVENT_NAME, payload);
  } catch (error) {
    logError({ message: `Error on Tracking (GTM) : ${EVENT_NAME}`, error });
  }
};
