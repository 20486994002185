import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LoaderWhite from '@assets/icons/white-loader.svg';
import continueIcon from '@assets/icons/arrow-rigth-white.svg';
import backIcon from '@assets/icons/arrow-left-red.svg';
import LoaderBlue from '@assets/icons/blue-loader.svg';
import StyledButton from './styled-components/Button';

function Button({
  innerText,
  showContinueIcon,
  showBackIcon,
  width,
  height,
  onClick,
  disabled,
  primary,
  loading,
  className,
  type,
  margin,
  fontSize,
}) {
  const onButtonClick = () => {
    if (onClick && !loading && !disabled) onClick();
  };
  return (
    <StyledButton
      width={width}
      height={height}
      type={type}
      className={className}
      isLoading={loading}
      disabled={disabled}
      margin={margin}
      fontSize={fontSize}
      onClick={() => {
        if (!loading) onButtonClick();
      }}
      primary={primary}
    >
      {loading && <img src={primary ? LoaderWhite : LoaderBlue} />}
      {!loading && showBackIcon && <img src={backIcon} />}
      {!loading && <div style={{ width: '100%', display: 'inline-block' }}>{innerText}</div>}
      {!loading && showContinueIcon && <img src={continueIcon} />}
    </StyledButton>
  );
}

Button.propTypes = {
  innerText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  loading: PropTypes.bool,
  showContinueIcon: PropTypes.bool,
  showBackIcon: PropTypes.bool,
  className: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  type: PropTypes.string,
  fontSize: PropTypes.string,
};

export default styled(Button)``;
