import styled from 'styled-components';

export const HeaderDropdown = styled.div`
  position: relative;
  color: ${props => props.theme.colors.White};
  align-items: center;
  font-weight: bold;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: 130px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  border: 1px solid transparent;
  &:hover {
    ul {
      z-index: 10;
      visibility: visible;
      a {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        border-bottom: none;
        margin: 0px 8px 0px 8px;
      }
    }
  }
`;

export const DropdownLabelContainer = styled.div`
  display: flex;
  padding: 6px 16px;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9em;
  width: 140px;
  padding: 27px 15px;
  ${props =>
    props.isSelected &&
    `padding-bottom: 24px;
    border-bottom: 3px solid ${props.theme.colors.White};
  `}
`;

export const DropdownItemsContainer = styled.ul`
  position: absolute;
  visibility: hidden;
  margin: 0;
  padding: 5px;
  border-radius: 0 0 5px 5px;
  background: white;
  box-shadow: 0 6px 6px 0 rgba(39, 50, 57, 0.15);
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: center;
  width: auto;
  min-width: 128px;
  top: 53px;
  left: 0;
  a {
    margin: 0;
    color: ${props => props.theme.colors.Main};
  }
`;
