export const MODULE_NAME = 'share-order-status';
export const ORDER = 'order';
export const TRACKING_INFO = 'trackingInfo';
export const TRACKING_INFO_EMPTY = 'trackingInfoEmpty';
export const TRACKING_COUNTER = 'trackingCounter';
export const FAILURE_TRACKING = 'failureTracking';
export const IS_FETCHING_TRACKING = 'isFetchingTracking';
export const HAS_FETCHED_TRACKING = 'HAS_FETCHED_TRACKING';

export const STATUSES_FROM_CS = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  PICKED_UP: 'picked_up',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const STATUSES_FROM_CSS = {
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
  PREORDER: 'PREORDER',
  CONFIRMED: 'CONFIRMED',
  IN_PROGRESS: 'IN_PROGRESS',
  NEAR_PICKUP: 'NEAR_PICKUP',
  PICKED_UP: 'PICKED_UP',
  NEAR_DROPOFF: 'NEAR_DROPOFF',
  COMPLETED: 'COMPLETED',
};

export const STATUSES_FROM_PEYA = {
  PEYA_PENDING: 'peya_pending',
  PEYA_CONFIRMED: 'peya_confirmed',
  PEYA_PICKED_UP: 'peya_picked_up',
  PEYA_CANCELLED: 'peya_cancelled',
  PEYA_COMPLETED: 'peya_completed',
  PEYA_REJECTED: 'peya_rejected',
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  PICKED_UP: 'PICKED_UP',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
};

export const ORDER_FROM = {
  API: 'API',
  B2B: 'B2B',
  C2C: 'C2C',
  PP_ORDER: 'PP_ORDER',
};

export const STEPS = {
  [ORDER_FROM.API]: [
    {
      status: [STATUSES_FROM_CSS.CONFIRMED],
    },
    {
      status: [STATUSES_FROM_CSS.IN_PROGRESS],
    },
    {
      status: [STATUSES_FROM_CSS.NEAR_PICKUP],
    },
    {
      status: [STATUSES_FROM_CSS.PICKED_UP],
    },
    {
      status: [STATUSES_FROM_CSS.NEAR_DROPOFF],
    },
  ],
  [ORDER_FROM.B2B]: [
    {
      status: [STATUSES_FROM_CSS.CONFIRMED],
    },
    {
      status: [STATUSES_FROM_CSS.IN_PROGRESS],
    },
    {
      status: [STATUSES_FROM_CSS.NEAR_PICKUP],
    },
    {
      status: [STATUSES_FROM_CSS.PICKED_UP],
    },
    {
      status: [STATUSES_FROM_CSS.NEAR_DROPOFF],
    },
  ],
  [ORDER_FROM.C2C]: [
    {
      status: [STATUSES_FROM_CSS.CONFIRMED],
    },
    {
      status: [STATUSES_FROM_CSS.IN_PROGRESS],
    },
    {
      status: [STATUSES_FROM_CSS.NEAR_PICKUP],
    },
    {
      status: [STATUSES_FROM_CSS.PICKED_UP],
    },
    {
      status: [STATUSES_FROM_CSS.NEAR_DROPOFF],
    },
  ],
  [ORDER_FROM.PP_ORDER]: [
    {
      status: [STATUSES_FROM_PEYA.PEYA_PENDING],
    },
    {
      status: [STATUSES_FROM_PEYA.PEYA_CONFIRMED],
    },
    {
      status: [STATUSES_FROM_PEYA.PEYA_PICKED_UP],
    },
  ],
};
