import { defineMessages } from 'react-intl';

export default defineMessages({
  aboutUs: {
    id: 'src.components.header.aboutUs',
    defaultMessage: 'Quiénes somos',
  },
  supportCenter: {
    id: 'src.components.header.supportCenter',
    defaultMessage: 'Centro de ayuda',
  },
  myOrders: {
    id: 'src.components.header.myOrders',
    defaultMessage: 'Mis envíos',
  },
  createOrder: {
    id: 'src.components.header.createOrder',
    defaultMessage: 'Crear envío',
  },
  createCommonOrder: {
    id: 'src.components.header.createCommonOrder',
    defaultMessage: 'Crear un envío',
  },
  myAddresses: {
    id: 'src.components.header.myAddresses',
    defaultMessage: 'Mis direcciones',
  },
  myAccount2: {
    id: 'src.components.header.myAccount2',
    defaultMessage: 'Cuenta',
  },
  myProfile: {
    id: 'src.components.header.myProfile',
    defaultMessage: 'Datos de la cuenta',
  },
  billing: {
    id: 'src.components.header.billing',
    defaultMessage: 'Facturación',
  },
  apiCredentials: {
    id: 'src.components.header.apiCredentials',
    defaultMessage: 'Integraciones',
  },
  suggest: {
    id: 'src.components.header.suggest',
    defaultMessage: 'Sugerencias',
  },
  logout: {
    id: 'src.components.header.logout',
    defaultMessage: 'Cerrar sesión',
  },
  ordersBulk: {
    id: 'src.components.header.ordersBulk',
    defaultMessage: 'Crear envíos  masivamente',
  },
  integrationsTooltip: {
    id: 'src.components.tooltip.integrations',
    defaultMessage:
      'Conocé cómo potenciar tu negocio integrando PedidosYa Envíos en tu e\u2011commerce.',
  },
});
