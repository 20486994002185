import { createSelector } from 'reselect';
import { MODULE_NAME, FLAGS } from '@funWithFlags/constants';
import isVariation from '../utils/isVariation';

const getPropFromModule = (state, ...props) => state[MODULE_NAME][props];

const getIsPartnerIntegrated = createSelector(
  state => getPropFromModule(state, 'flags'),
  flags => isVariation(flags[FLAGS.INTEGRATED_PARTNER.KEY]),
);

export default getIsPartnerIntegrated;
