const messages = {
  title: {
    id: 'src.components.errorHandler.title',
    defaultMessage: 'Parece que hay un problema',
  },
  subtitle: {
    id: 'src.components.errorHandler.subtitle',
    defaultMessage: 'Intentá recargar la página o volver en unos minutos.',
  },
};

export default messages;
