import { createSelector } from 'reselect';
import { MODULE_NAME, FLAGS } from '@funWithFlags/constants';
import isVariation from '../utils/isVariation';

const getPropFromModule = (state, ...props) => state[MODULE_NAME][props];

const getShowLandingPageFlag = createSelector(
  (state) => getPropFromModule(state, 'flags'),
  (flags) => true, // isVariation(flags[FLAGS.SHOW_LANDING_PAGE.KEY]),
);

export default getShowLandingPageFlag;
