import { createSelector } from 'reselect';
import { USER_LOGGED } from '@user/constants';

import getPropFromModule from './getPropFromModule';

const getTotalPartners = createSelector(
  state => getPropFromModule(state, USER_LOGGED),
  userLogged => userLogged?.totalPartners || 0,
);

export default getTotalPartners;
