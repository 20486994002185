export const PROCESS_ROWS = 'app/orders-bulk/PROCESS_ROWS';
export const VALIDATE_BRANCHES = 'app/orders-bulk/VALIDATE_BRANCHES';
export const ADD_NEW_ORDER = 'app/orders-bulk/ADD_NEW_ORDER';
export const UPDATE_ORDER = 'app/orders-bulk/UPDATE_ORDER';
export const DELETE_ORDER = 'app/orders-bulk/DELETE_ORDER';
export const DELETE_SELECTED_ORDERS = 'app/orders-bulk/DELETE_SELECTED_ORDERS';
export const TOGGLE_ALL_SELECTED = 'app/orders-bulk/TOGGLE_ALL_SELECTED';
export const CREATE_ORDERS = 'app/orders-bulk/CREATE_ORDERS';
export const CREATE_ORDER = 'app/orders-bulk/CREATE_ORDER';
export const SET_SCHEDULE_DATE = 'app/orders-bulk/SET_SCHEDULE_DATE';
export const SET_SCHEDULE_TIME = 'app/orders-bulk/SET_SCHEDULE_TIME';
export const CLEAR_ORDERS = 'app/orders-bulk/CLEAR_ORDERS';
export const CLEAR_FILE_ORDERS = 'app/orders-bulk/CLEAR_FILE_ORDERS';
export const CLEAR_BULK_STORE = 'app/orders-bulk/CLEAR_BULK_STORE';
export const VALIDATE_DELIVERY_ZONES = 'app/orders-bulk/VALIDATE_DELIVERY_ZONES';
