/* eslint-disable no-unused-vars */
import { createSelector } from 'reselect';
import { MODULE_NAME, FLAGS } from '@funWithFlags/constants';
import isVariation from '../utils/isVariation';

const getPropFromModule = (state, ...props) => state[MODULE_NAME][props];

const getRolloutDHHelpCenterFlag = createSelector(
  state => getPropFromModule(state, 'flags'),
  // TODO review when on-demand-rider is available
  flags => true, // isVariation(flags[FLAGS.ROLLOUT_DH_HELP_CENTER.KEY]),
);

export default getRolloutDHHelpCenterFlag;
