import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 90vh;
  background-color: ${props => props.theme.colors.backgroundGrey};
`;

export default Container;
