import {
  IS_DOING_LOGIN,
  ERROR_MESSAGE,
  USER_LOGGED,
  IS_DOING_LOGOUT,
  FROM_LOGOUT,
  BRANCHES,
  ONBOARDING,
  CONTACT_DATA,
  ADDRESS_BILLING,
  BUSINESS_DATA,
  USERS_HAS_FETCHED,
  SELECTED_PARTNER,
  IS_CREATING_CREDENTIALS,
  IS_GETTING_STATUS_PARTNER,
  ROLES,
  IS_SENDING_CREATION_PARTNER,
  ERROR_GENERIC_ON_CREATE_PARTNER,
  ERROR_TAXNUMBER_DUPLICATE,
  ERROR_TAXNUMBER_INVALID,
  ERROR_COMERCIAL_NAME_DUPLICATE,
  PARTNER_WAS_CREATED,
  FETCHING_USER_TYPE,
  MY_PROFILE_DATA,
  MY_CONTACT_DATA,
} from '@user/constants';

const ModuleStateModel = {
  [IS_DOING_LOGIN]: false,
  [IS_DOING_LOGOUT]: false,
  [FROM_LOGOUT]: false,
  [ROLES]: [],
  [IS_CREATING_CREDENTIALS]: false,
  [USERS_HAS_FETCHED]: false,
  [SELECTED_PARTNER]: '',
  [USER_LOGGED]: {},
  [BRANCHES]: [],
  [ONBOARDING]: {
    [CONTACT_DATA]: {},
    [ADDRESS_BILLING]: {},
    [BUSINESS_DATA]: {},
    [ERROR_GENERIC_ON_CREATE_PARTNER]: false,
    [ERROR_TAXNUMBER_DUPLICATE]: false,
    [ERROR_TAXNUMBER_INVALID]: false,
    [ERROR_COMERCIAL_NAME_DUPLICATE]: false,
    [PARTNER_WAS_CREATED]: false,
  },
  [IS_GETTING_STATUS_PARTNER]: false,
  [IS_SENDING_CREATION_PARTNER]: false,
  [ERROR_MESSAGE]: '',
  [FETCHING_USER_TYPE]: false,
  [MY_PROFILE_DATA]: {
    [MY_CONTACT_DATA]: {},
  },
};

export default ModuleStateModel;
