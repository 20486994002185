import { SESSION_STORAGE_KEY } from '@modules/contact-support/care-center/constants';
import { save } from '@services/sessionStorage';
import getCareCenterInstances from '@services/hooks/getCareCenterInstances';

function updateCareCenterInstance(event) {
  const activitySrc = event?.data?.href;
  const id = event?.data?.id;
  if (!activitySrc) return null;

  const instances = getCareCenterInstances() || [];

  const indexInArray = instances.findIndex(x => x.id === id);

  if (indexInArray !== -1) {
    instances[indexInArray] = {
      src: activitySrc,
      id: instances[indexInArray].id,
    };
    save(SESSION_STORAGE_KEY, JSON.stringify(instances));
  }

  return instances;
}

export default updateCareCenterInstance;
