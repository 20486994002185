export function isEmptyObject(obj) {
  return !Object.keys(obj).length;
}

export function ignore(map = {}, propsToFilter = []) {
  return Object.keys(map).reduce((acc, key) => {
    if (propsToFilter.indexOf(key) === -1) acc[key] = map[key];
    return acc;
  }, {});
}
