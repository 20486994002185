export const MODULE_NAME = 'location';
export const IS_GOOGLE_MAPS_LOADED = 'isGoogleMapLoaded';
export const HAS_FETCHED_COORDINATES = 'hasFetchedCoordinates';
export const COORDINATES = 'coordinates';
export const ERROR_MESSAGE = 'errorMessage';
export const STREET_TYPES = 'streetTypes';
export const AREA_ID = 'areaId';

export const MAP_ZOOM = {
  CITY: 13,
  WITH_MARKER: 15,
};
