import styled from 'styled-components';

const HeaderButton = styled.img`
  border: none;
  cursor: pointer;
  padding: 4px;
  margin-left: 2px;
  transform: rotate(0deg);
  overflow: hidden;
  transform: ${props => (!props.isVisible ? `rotate(180deg)` : '')};
  transition: all 0.3s ease-out;
`;

export default HeaderButton;
