import {
  ORDER,
  TRACKING_INFO,
  FAILURE_TRACKING,
  IS_FETCHING_TRACKING,
  HAS_FETCHED_TRACKING,
  TRACKING_INFO_EMPTY,
  TRACKING_COUNTER,
} from '@share-order-status/constants';

const ModuleStateModel = {
  [ORDER]: {},
  [TRACKING_INFO]: {},
  [FAILURE_TRACKING]: false,
  [IS_FETCHING_TRACKING]: false,
  [HAS_FETCHED_TRACKING]: false,
  [TRACKING_INFO_EMPTY]: false,
  [TRACKING_COUNTER]: 0,
};

export default ModuleStateModel;
