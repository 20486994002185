import { createSelector } from 'reselect';
import { IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN } from '@user/constants';

import getPropFromModule from './getPropFromModule';

const getIsFetchingPossiblesPartnersToDoLogin = createSelector(
  state => getPropFromModule(state, IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN),
  isFetchingPossiblesPartnersToDoLogin => isFetchingPossiblesPartnersToDoLogin || false,
);

export default getIsFetchingPossiblesPartnersToDoLogin;
