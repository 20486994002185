import React from 'react';
import PropTypes from 'prop-types';
import minimize from '@assets/icons/minimize-arrow.svg';
import close from '@assets/icons/close-white.svg';
import useCareCenter from '@hooks/useCareCenter';
import { GENERAL_ONLINE_SUPPORT_ID } from './constants';
import CloseModalWarning from './components/CloseModalWarning';
import IframeContainer from './styled-components/IframeContainer';
import Iframe from './styled-components/IFrame';
import Header from './styled-components/Header';
import HeaderButton from './styled-components/HeaderButton';
import ButtonsContainer from './styled-components/ButtonsContainer';

const CareCenter = ({ instance, index, setCareCenterInstances, careCenterInstances }) => {
  const ref = React.createRef();
  const [isVisible, setIsVisible] = React.useState(
    typeof instance.triggerAutoOpen === 'boolean' ? instance.triggerAutoOpen : true,
  );
  const [showModalOnClose, setShowModalOnClose] = React.useState(false);
  const [arrowEffect, setArrowEffect] = React.useState(true);
  const { deleteInstance } = useCareCenter();

  React.useEffect(() => {
    setTimeout(() => setArrowEffect(isVisible), 300);
  }, [isVisible]);

  React.useEffect(() => {
    if (typeof instance.triggerAutoOpen === 'boolean') {
      setIsVisible(instance.triggerAutoOpen);
    }
  }, [instance]);

  const onCloseCareCenter = () => {
    if (instance.id === GENERAL_ONLINE_SUPPORT_ID) {
      setShowModalOnClose(true);
    } else onDeleteCareCenterInstance();
  };

  const onDeleteCareCenterInstance = async () => {
    const instancesCopy = [...careCenterInstances];
    instancesCopy.splice(index, 1);

    const findIndex = careCenterInstances.findIndex(x => x.id === instance.id);

    if (findIndex !== -1) {
      const instancesToSave = [...careCenterInstances];
      instancesToSave.splice(findIndex, 1);
      setCareCenterInstances(instancesToSave);
    }

    deleteInstance(instance);
    setTimeout(() => {
      const generalHelpCenterId = GENERAL_ONLINE_SUPPORT_ID;
      if (instance.id === generalHelpCenterId) {
        const instancesToSave = [...careCenterInstances];
        setCareCenterInstances(instancesToSave);
      }
    }, 1000);
  };

  return (
    <IframeContainer isVisible={isVisible} index={index}>
      <Header isVisible={isVisible} onClick={() => !isVisible && setIsVisible(true)}>
        {instance.id}
        <ButtonsContainer>
          <HeaderButton
            isVisible={arrowEffect}
            src={minimize}
            onClick={() => setIsVisible(!isVisible)}
          />
          {isVisible && <HeaderButton src={close} onClick={onCloseCareCenter} />}
        </ButtonsContainer>
      </Header>
      {showModalOnClose && (
        <CloseModalWarning
          onClose={onDeleteCareCenterInstance}
          onCancel={() => setShowModalOnClose(false)}
        />
      )}
      <Iframe
        ref={ref}
        name={instance.id}
        src={instance.src}
        title="care-center"
        width="380px"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        height="514px"
      />
    </IframeContainer>
  );
};

CareCenter.propTypes = {
  instance: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  careCenterInstances: PropTypes.array.isRequired,
  setCareCenterInstances: PropTypes.func.isRequired,
};

export default CareCenter;
