export const MODULE_NAME = 'locationManager';
export const FREQUENT_ADDRESSES = 'frequentAddresses';
export const BRANCHES = 'branches';
export const ADDRESS_SELECTED = 'addressSelected';
export const AUTOCOMPLETE_ADDRESSES = 'autocompleteAddresses';
export const FREQUENT_ADDRESSES_FILTERS = 'frequentAddressesFilters';
export const BRANCHES_FILTERS = 'branchesFilters';
export const CLEAR_FREQUENTS_ADDRESSES = 'clearFrequentsAddresses';

export const ADDRESS_TYPE = {
  BRANCH: 'BRANCH',
  FREQUENT: 'FREQUENT',
  AUTOCOMPLETE: 'AUTOCOMPLETE',
  FREQUENT_ADDRESSES_FILTERS: 'FREQUENT_ADDRESSES_FILTERS',
  BRANCHES_FILTERS: 'BRANCHES_FILTERS',
  CLEAR_FREQUENTS_ADDRESSES: 'CLEAR_FREQUENTS_ADDRESSES',
};
