import {
  STG_ENVIRONMENT,
  LOCAL_ENVIRONMENT,
  DEV_ENVIRONMENT,
  PROD_ENVIRONMENT,
  getEnv,
} from '@utils/environment';
import { COUNTRY_SHORT_NAME_STAGING, COUNTRY_SHORT_NAME_LIVE } from './constants';
import pkg from '../../package.json';

const LoginStg = 'https://stg-authenticate.pedidosya.com';
const LoginLive = 'https://authenticate.pedidosya.com';

const CareStg =
  'https://stg-care-center.pedidosya.com/activity/01bb22f1-d2a5-4d33-ad66-545d0490e5ab';
const CareLive = 'https://care-center.pedidosya.com/activity/e809abb4-6920-4cd0-9c40-d66f542a9d9d';

const createOrderLocal = 'http://localhost:3001';

export const getLoginUrl = () => {
  switch (getEnv()) {
    case STG_ENVIRONMENT:
    case LOCAL_ENVIRONMENT:
    case DEV_ENVIRONMENT:
      return LoginStg;
    case PROD_ENVIRONMENT:
      return LoginLive;
    default:
      return LoginStg;
  }
};

export const getCareBaseUrl = () => {
  switch (getEnv()) {
    case STG_ENVIRONMENT:
    case LOCAL_ENVIRONMENT:
    case DEV_ENVIRONMENT:
      return CareStg;
    case PROD_ENVIRONMENT:
      return CareLive;
    default:
      return CareStg;
  }
};

export const getCreateOrderIframeSrc = countryShortName => {
  switch (getEnv()) {
    case LOCAL_ENVIRONMENT:
    case DEV_ENVIRONMENT:
      return createOrderLocal;
    case STG_ENVIRONMENT:
      return COUNTRY_SHORT_NAME_STAGING[countryShortName];
    case PROD_ENVIRONMENT:
      return COUNTRY_SHORT_NAME_LIVE[countryShortName];
    default:
      return COUNTRY_SHORT_NAME_STAGING[countryShortName];
  }
};

export function urlFormatter(str, ...args) {
  return str.replace(/{(\d+)}/g, (match, number) =>
    typeof args[number] !== 'undefined' ? args[number] : match,
  );
}
const apiGatewayProxyUrl = () => '/api/gateway';
const partnerProxyUrl = () => '/api/partner';
const locationProxyUrl = () => '/api/locations';

export const bffBaseUrl = () => `/api/${pkg.name}`;

// **********************  BFF URLS  ***************************** //
export const doLogoutUrl = () => `${bffBaseUrl()}/logout`;
export const createOrUpdateCredentialsV2URL = () => `${bffBaseUrl()}/v2/credentials`;
export const createFixedTokenURL = () => `${bffBaseUrl()}/credentials/fixedToken`;
export const createOrUpdateCredentialsWithParamsURL = () =>
  `${bffBaseUrl()}/credentials?appKey={0}`;
export const sendOrderUrl = () => `${bffBaseUrl()}/shipping`;
export const postEstimationUrl = () => `${bffBaseUrl()}/shipping/estimation`;
export const getLabelsURL = () => `${bffBaseUrl()}/shipping/labels?shippingIds={0}`;
export const getTrackingInfoUrl = () =>
  `${bffBaseUrl()}/shipping/tracking?orderID={0}&from={1}&userID={2}&withWaypoints={3}`;

export const getPartnerRolesByPartnerIdURL = () => `${bffBaseUrl()}/partnerRoles`;
export const getCredentialTypesURL = () => `${bffBaseUrl()}/credentials/types`;
export const getOnlineSupportURL = () => `${bffBaseUrl()}/onlineSupportUrls`;
export const getContactCenterURL = () =>
  `${bffBaseUrl()}/clients/{0}/users/{1}/orders/{2}/contactCenterUrl`;
export const getContactDataUrl = () => `${bffBaseUrl()}/myAccount/contact`;

export const getPartnersURL = partnerName =>
  partnerName
    ? `${bffBaseUrl()}/user/partners?partnerName=${partnerName}`
    : `${bffBaseUrl()}/user/partners`;
// **************************************************************** //

// *****************  Location Proxy URLS  ************************ //
export const getAutocompleteUrl = () =>
  `${locationProxyUrl()}/v2/geo/autocomplete?input={0}&country={1}&lat={2}&lng={3}`;

export const getPlaceByIdUrl = () => `${locationProxyUrl()}/v2/geo/place/{0}?place_type={1}`;
export const getReverseGeoUrl = () =>
  `${locationProxyUrl()}/v2/geo/reverse?lat={0}&lng={1}&uuid={2}"`;
// **************************************************************** //

// **********************  Proxy URLS  ********************** //

// *****************  Partner Service Proxy URLS  ************************ //
/* Branches */
export const getPartnerBranchesUrl = () =>
  `${partnerProxyUrl()}/v1/partners/:partnerId/branches?deleted=false`;
export const getPartnerBranchesByIDUrl = () =>
  `${partnerProxyUrl()}/v1/partners/:partnerId/branches/{0}`;
export const savePartnerBranchesUrl = () => `${partnerProxyUrl()}/v1/partners/:partnerId/branches`;
export const updatePartnerBranchUrl = () =>
  `${partnerProxyUrl()}/v1/partners/:partnerId/branches/{0}`;
export const deletePartnerBranchUrl = () =>
  `${partnerProxyUrl()}/v1/partners/:partnerId/branches/{0}`;

/* Frequent */
export const getAllFrequentAddressesUrl = () =>
  `${partnerProxyUrl()}/v1/partners/:partnerId/users/:userId/frequentAddresses?count=10`;
export const getFrequentAddressesUrl = () =>
  `${partnerProxyUrl()}/v1/partners/:partnerId/users/:userId/frequentAddresses?contains={0}`;
export const getFrequentsAddressByIdUrl = () =>
  `${partnerProxyUrl()}/v1/partners/:partnerId/users/:userId/frequentAddresses/{0}`;
export const deleteFrequentsAddressIdUrl = () =>
  `${partnerProxyUrl()}/v1/partners/:partnerId/users/:userId/frequentAddresses/{0}`;

/* SSU */
export const getPartnerStatusByUserIdUrl = () => `${partnerProxyUrl()}/v1/users/:userId/partners`;
export const savePartnerDataUrl = () =>
  `${partnerProxyUrl()}/v1/users/:userId/partners`; /* from march 2024 this saves the data to a google spreadsheet */

/** ********************************************************************************* */

// API GATEWAY SERVICES
export const getPhoneFlagsURL = () =>
  `${apiGatewayProxyUrl()}/v1/validation/send_code/phone/config`;
