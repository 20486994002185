import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import newRebrandingFlag from '@funWithFlags/selectors/getNewRebrandingFlag';

import HeaderLinkWithIcon from './HeaderLinkWithIcon';
const mapStateToProps = createStructuredSelector({
  newRebrandingFlag,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinkWithIcon);
