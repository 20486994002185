import styled from 'styled-components';

const Icon = styled.div`
  display: flex;
  width: 12px;
  margin: 12px 12px 0px 12px;
  cursor: pointer;
`;

export default Icon;
