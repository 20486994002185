import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10.6347L19.7921 2.84269C20.2972 2.33759 21.1161 2.33759 21.6212 2.84269C22.1263 3.34779 22.1263 4.16671 21.6212 4.67181L13.8291 12.4639L21.6212 20.2559C22.1263 20.761 22.1263 21.5799 21.6212 22.085C21.1161 22.5901 20.2972 22.5901 19.7921 22.085L12 14.293L4.20794 22.085C3.70285 22.5901 2.88392 22.5901 2.37882 22.085C1.87373 21.5799 1.87373 20.761 2.37882 20.2559L10.1709 12.4639L2.37882 4.67181C1.87373 4.16671 1.87373 3.34779 2.37882 2.84269C2.88392 2.33759 3.70285 2.33759 4.20794 2.84269L12 10.6347Z"
    />
  </svg>
);

CloseIcon.propTypes = {
  fill: PropTypes.string,
};

export default CloseIcon;
