export const MODULE_NAME = 'orders-bulk';
export const ORDERS = 'orders';
export const SCHEDULE_TIME = 'scheduleTime';
export const SCHEDULE_DATE = 'scheduleDate';
export const ORDERS_WAS_SENT = 'ordersWasSent';
export const IS_SENDING_ORDERS = 'isSendingOrders';
export const INVALID_BRANCH = 'invalidBranch';
export const MAX_ORDERS = 400;
export const schemaNames = {
  ALIAS: 'alias',
  NAME_AND_SURNAME: 'name',
  ADDRESS: 'address',
  PHONE_NUMBER: 'phoneNumber',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  MONEY_COLLECT: 'collectAtDropOff',
  ORDER_PRODUCT: 'orderProduct',
  WEIGHT: 'weight',
  VOLUME: 'volume',
  INSTRUCTIONS: 'instructions',
};

export const schemaOrders = {
  ALIAS: {
    prop: schemaNames.ALIAS,
    type: String,
    required: false,
  },
  NAME_AND_SURNAME: {
    prop: schemaNames.NAME_AND_SURNAME,
    type: String,
    required: false,
  },
  ADDRESS: {
    prop: schemaNames.ADDRESS,
    type: String,
    required: false,
  },
  PHONE_NUMBER: {
    prop: schemaNames.PHONE_NUMBER,
    type: String,
    required: false,
  },
  LATITUDE: {
    prop: schemaNames.LATITUDE,
    type: Number,
    required: false,
  },
  LONGITUDE: {
    prop: schemaNames.LONGITUDE,
    type: Number,
    required: false,
  },
  MONEY_COLLECT: {
    prop: schemaNames.MONEY_COLLECT,
    type: Number,
    required: false,
  },
  ORDER_PRODUCT: {
    prop: schemaNames.ORDER_PRODUCT,
    type: String,
    required: false,
  },
  WEIGHT: {
    prop: schemaNames.WEIGHT,
    type: Number,
    required: false,
  },
  VOLUME: {
    prop: schemaNames.VOLUME,
    type: Number,
    required: false,
  },
  INSTRUCTIONS: {
    prop: schemaNames.INSTRUCTIONS,
    type: String,
    required: false,
  },
};

export const schemaNamesWithStatus = {
  CHECKED: 'checkbox',
  ROW: 'row',
  ...schemaNames,
  STATUS: 'status',
};

export const headerTitles = {
  checkbox: '',
  row: 'N°',
  alias: 'Dirección de retiro*',
  name: 'Por quién preguntamos*',
  address: 'Dirección de entrega*',
  phoneNumber: 'Teléfono*',
  latitude: 'Latitud*',
  longitude: 'Longitud*',
  collectAtDropOff: 'Valor a cobrar en destino ($)',
  orderProduct: 'Producto a enviar*',
  weight: 'Peso(Kg)',
  volume: 'Volumen',
  instructions: 'Instrucciones de entrega',
  status: 'Estado',
};
