/* eslint-disable no-param-reassign,consistent-return */
import produce from 'immer';
import successAction from '@global/actions/successAction';
import failAction from '@global/actions/failAction';
import ModuleStateModel from './defaultState';
import * as modelKeys from './constants';
import * as actions from './actionTypes';

const initialState = ModuleStateModel;

function ordersBulkModuleReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actions.FETCH_TRACKING: {
        draft[modelKeys.IS_FETCHING_TRACKING] = true;
        break;
      }

      case actions.CLEAR_RIDER_MARKER: {
        draft[modelKeys.TRACKING_INFO] = {};
        break;
      }

      case failAction({ type: actions.FETCH_TRACKING }): {
        draft[modelKeys.FAILURE_TRACKING] = true;
        draft[modelKeys.IS_FETCHING_TRACKING] = false;
        draft[modelKeys.HAS_FETCHED_TRACKING] = true;
        break;
      }

      case successAction({ type: actions.FETCH_TRACKING }): {
        const { order, trackingInfo } = action?.payload;
        const prevTrackingInfoEmpty = !!state[modelKeys.TRACKING_INFO_EMPTY];
        const counter = draft[modelKeys.TRACKING_COUNTER];
        draft[modelKeys.ORDER] = {
          ...order,
          dropOff: order.dropOff || state[modelKeys.ORDER]?.dropOff,
          pickUp: order.pickUp || state[modelKeys.ORDER]?.pickUp,
        };
        draft[modelKeys.IS_FETCHING_TRACKING] = false;
        draft[modelKeys.FAILURE_TRACKING] = false;
        draft[modelKeys.HAS_FETCHED_TRACKING] = true;
        draft[modelKeys.TRACKING_INFO_EMPTY] = !trackingInfo;

        if (trackingInfo && shouldSaveTrackingInfo(order.status)) {
          if (counter !== 0) {
            delete trackingInfo.estimatedDropOffTime;
            delete trackingInfo.estimatedPickUpTime;
          }
          draft[modelKeys.TRACKING_INFO] = {
            ...state[modelKeys.TRACKING_INFO],
            ...trackingInfo,
          };
          if (counter === 4) {
            draft[modelKeys.TRACKING_COUNTER] = 0;
          } else {
            draft[modelKeys.TRACKING_COUNTER] += 1;
          }
        } else if (prevTrackingInfoEmpty) {
          draft[modelKeys.TRACKING_INFO] = {};
        }
        break;
      }

      default:
        return draft;
    }
  });
}

const shouldSaveTrackingInfo = status =>
  status === modelKeys.STATUSES_FROM_CS.CONFIRMED ||
  status === modelKeys.STATUSES_FROM_CS.PICKED_UP ||
  status === modelKeys.STATUSES_FROM_CSS.CONFIRMED ||
  status === modelKeys.STATUSES_FROM_CSS.IN_PROGRESS ||
  status === modelKeys.STATUSES_FROM_CSS.NEAR_PICKUP ||
  status === modelKeys.STATUSES_FROM_CSS.NEAR_DROPOFF ||
  status === modelKeys.STATUSES_FROM_CSS.PICKED_UP ||
  status === modelKeys.STATUSES_FROM_CSS.CANCELLED ||
  status === modelKeys.STATUSES_FROM_CS.CANCELLED ||
  status === modelKeys.STATUSES_FROM_PEYA.PEYA_PENDING ||
  status === modelKeys.STATUSES_FROM_PEYA.PEYA_CONFIRMED ||
  status === modelKeys.STATUSES_FROM_PEYA.PEYA_PICKED_UP ||
  status === modelKeys.STATUSES_FROM_PEYA.PEYA_COMPLETED ||
  status === modelKeys.STATUSES_FROM_PEYA.PEYA_CANCELLED;

export default ordersBulkModuleReducer;
