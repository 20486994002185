import { SESSION_STORAGE_KEY } from '@modules/contact-support/care-center/constants';
import getCareCenterInstances from '@services/hooks/getCareCenterInstances';
import { save } from '@services/sessionStorage';
import deleteCareCenterInstance from '@services/hooks/deleteCareCenterInstance';

function addCareCenterInstance(newInstance) {
  deleteCareCenterInstance(newInstance);
  const instances = getCareCenterInstances() || [];
  const dataToReturn = [...instances, newInstance];

  save(SESSION_STORAGE_KEY, JSON.stringify(dataToReturn));
  return dataToReturn;
}

export default addCareCenterInstance;
