import { createSelector } from 'reselect';
import getUserPermission from '@user/selectors/getUserPermission';
import { ROL_MONITOR } from '@utils/constants';

const getUserCanCreateOrder = createSelector(
  getUserPermission,
  userPermission => (userPermission && userPermission !== ROL_MONITOR) || false,
);

export default getUserCanCreateOrder;
