import { createSelector } from 'reselect';
import { SELECTED_COUNTRY } from '@global/constants';

import getPropFromModule from './getPropFromModule';

const getSelectedCountry = createSelector(
  state => getPropFromModule(state, SELECTED_COUNTRY),
  selectedCountry => selectedCountry,
);

export default getSelectedCountry;
