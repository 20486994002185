import { save, get } from '@services/localStorage';

const LOCAL_STORAGE_PILLS_KEY = 'courier-web-LOCAL_STORAGE_PILLS_KEY';

const registeredPills = [];

const getPill = id => {
  const pills = registeredPills.filter(x => x.id === id);
  return pills.length ? pills[0] : undefined;
};

export const registerNewPill = (id, expirationDate) => {
  if (!getPill(id)) {
    registeredPills.push({ id, expirationDate });
  }
};

export const isNew = id => {
  if (!id) return false;

  const pill = getPill(id);

  // If pill is not registered
  if (!pill) return false;

  // If pill has expired
  if (new Date() > pill.expirationDate) return false;

  // If no pill has been clicked
  const currentValueStr = get(LOCAL_STORAGE_PILLS_KEY);
  if (!currentValueStr) return true;

  // If pill id has been clicked
  const currentValue = JSON.parse(currentValueStr);
  const clickedPill = currentValue.filter(x => x.id === id);
  if (!clickedPill.length) return false;

  return true;
};

export const registerClick = id => {
  if (!id) return;
  const currentValueStr = get(LOCAL_STORAGE_PILLS_KEY);

  if (currentValueStr) {
    const currentValue = JSON.parse(currentValueStr);
    const hasBeenClicked = currentValue.filter(x => x === id);
    if (!hasBeenClicked.length) {
      currentValue.push(id);
      save(LOCAL_STORAGE_PILLS_KEY, JSON.stringify(currentValue));
    }
  } else {
    save(LOCAL_STORAGE_PILLS_KEY, JSON.stringify([id]));
  }
};
