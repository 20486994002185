export const save = (key, value) => {
  if (typeof key !== 'string') {
    throw Error('Error saving in sessionStorage: Key must be string value');
  }

  if (typeof value !== 'string') {
    throw Error('Error saving in sessionStorage: Value must be string value');
  }

  sessionStorage.setItem(key, value);
};

export const get = key => {
  if (typeof key !== 'string') {
    throw Error('Error getting from sessionStorage: Key must be string value');
  }
  return sessionStorage.getItem(key);
};

export const remove = key => {
  if (typeof key !== 'string') {
    throw Error('Error deleting from sessionStorage: Key must be string value');
  }

  sessionStorage.removeItem(key);
};
