import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import ROUTES from '@services/routes';

function Render(Component, isUserLogged, isWrongUserType, userCanCreateOrder, props) {
  if (isUserLogged) {
    if (!isWrongUserType) return <Component {...props} />;

    return (
      <Redirect
        to={{
          pathname: userCanCreateOrder ? ROUTES.CREATE_ORDER : ROUTES.MY_ORDERS,
          state: { from: location },
        }}
      />
    );
  }
  return (
    <Redirect
      to={{
        pathname: ROUTES.LOGIN,
      }}
    />
  );
}

function PrivateRoute({
  render: RenderComponent,
  isUserLogged,
  userCanCreateOrder,
  userType,
  isUserTypeRestricted,
  requiredUserType,
  ...rest
}) {
  const isWrongUserType = isUserTypeRestricted && userType !== requiredUserType;
  return (
    <Route
      {...rest}
      render={props =>
        Render(RenderComponent, isUserLogged, isWrongUserType, userCanCreateOrder, {
          ...props,
          ...rest,
        })
      }
    />
  );
}

PrivateRoute.propTypes = {
  render: PropTypes.func.isRequired,
  isUserLogged: PropTypes.bool.isRequired,
  userCanCreateOrder: PropTypes.bool,
  userType: PropTypes.string,
  requiredUserType: PropTypes.string,
  isUserTypeRestricted: PropTypes.bool,
};

export default PrivateRoute;
