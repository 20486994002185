import {
  ORDERS,
  SCHEDULE_DATE,
  SCHEDULE_TIME,
  IS_SENDING_ORDERS,
  ORDERS_WAS_SENT,
} from './constants';

const ModuleStateModel = {
  [ORDERS]: [],
  [SCHEDULE_DATE]: { dateValue: new Date(), stringValue: '' },
  [SCHEDULE_TIME]: '',
  [IS_SENDING_ORDERS]: false,
  [ORDERS_WAS_SENT]: false,
};

export default ModuleStateModel;
