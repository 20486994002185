import styled from 'styled-components';

const IframeContainer = styled.div`
  overflow: hidden;
  height: ${props => (props.isVisible ? '550px' : '36px')};
  transition: all 0.3s ease-out;
  box-shadow: ${props => props.theme.containerShadow};
  position: fixed;
  flex-direction: column;
  z-index: 5000;
  bottom: 0;
  right: ${props =>
    !props.index
      ? '3%'
      : `calc(
    3% + ${380 * props.index}px + ${1 * props.index}%
  )`};
`;

export default IframeContainer;
