import {
  IS_GOOGLE_MAPS_LOADED,
  HAS_FETCHED_COORDINATES,
  COORDINATES,
  ERROR_MESSAGE,
  STREET_TYPES,
  AREA_ID,
} from '@location/constants';

const ModuleStateModel = {
  [IS_GOOGLE_MAPS_LOADED]: false,
  [HAS_FETCHED_COORDINATES]: false,
  [COORDINATES]: [],
  [ERROR_MESSAGE]: '',
  [STREET_TYPES]: [],
  [AREA_ID]: 0,
};

export default ModuleStateModel;
