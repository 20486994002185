/* eslint-disable no-param-reassign */
import produce from 'immer';
import successAction from '@global/actions/successAction';
import * as actions from './actionTypes';
import ModuleStateModel from './defaultState';
import * as modelKeys from './constants';

const initialState = ModuleStateModel;

function fwfModuleReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case successAction({ type: actions.FLAGS_UPDATED }): {
        const { flags } = action.payload;
        draft[modelKeys.FWF_FLAGS] = flags;
        break;
      }

      default:
        return draft;
    }
  });
}

export default fwfModuleReducer;
