import {
  FREQUENT_ADDRESSES,
  BRANCHES,
  ADDRESS_SELECTED,
  AUTOCOMPLETE_ADDRESSES,
  FREQUENT_ADDRESSES_FILTERS,
  BRANCHES_FILTERS,
} from '@location-manager/constants';

const ModuleStateModel = {
  [FREQUENT_ADDRESSES]: [],
  [BRANCHES]: [],
  [ADDRESS_SELECTED]: [],
  [AUTOCOMPLETE_ADDRESSES]: [],
  [FREQUENT_ADDRESSES_FILTERS]: [],
  [BRANCHES_FILTERS]: [],
};

export default ModuleStateModel;
