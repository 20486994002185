import styled from 'styled-components';

const HamburgerIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 6px;
  border: 1px solid ${props => props.theme.colors.elementLine};
  background-color: ${props => props.isOpen && props.theme.colors.White};
  border-radius: 4px;
  cursor: pointer;
  div {
    width: 20px;
    height: 2px;
    background: ${props =>
      props.isOpen ? props.theme.colors.Main : props.theme.colors.White};
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default HamburgerIconContainer;
