import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getPossiblePartnersToDoLogin from '@app/modules/user/selectors/getPossiblePartnersToDoLogin';
import doUpdateUserLogged from '@user/actions/doUpdateUserLogged';
import fetchPossiblePartnersToDoLogin from '@user/actions/fetchPossiblePartnersToDoLogin';
import getSelectedPartner from '@global/selectors/getSelectedPartner';
import getTotalPartners from '@app/modules/user/selectors/getTotalPartners';
import getIsFetchingPossiblesPartnersToDoLogin from '@app/modules/user/selectors/getIsFetchingPossiblesPartnersToDoLogin';

import PartnerSelector from './PartnerSelector';

const mapStateToProps = createStructuredSelector({
  possiblePartnersToDoLogin: getPossiblePartnersToDoLogin,
  selectedPartner: getSelectedPartner,
  totalPartners: getTotalPartners,
  isFetchingPossiblesPartnersToDoLogin: getIsFetchingPossiblesPartnersToDoLogin,
});

const mapDispatchToProps = { doUpdateUserLogged, fetchPossiblePartnersToDoLogin };

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSelector);
