export const SessionKeys = {
  GO_TO_AFTER_LOGIN: 'GO_TO_AFTER_LOGIN',
  COUNTRIES_PHONE_CONFIG: 'main.countries_phone_config',
};

export const LocalKeys = {
  TOURS: 'TOURS',
  NEW_BILLING_NOTIFICATION: 'NEW_BILLING_NOTIFICATION',
  FIRST_TIME_PROOF_OF_DELIVERY: 'main.first_time_proof_of_delivery',
};
