import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, createContext, useMemo } from 'react';
import axios from 'axios';
import BrandLoader from '@appscore/web-components/BrandLoader';
import { BrandError } from '@appscore/web-components/BrandError';

import pkg from '../../../package.json';

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const { data } = await axios.get(`/api/${pkg.name}/env`);
  return data;
};

export const usePublicEnv = () => useContext(PublicEnvContext);

export const PublicEnvProvider = ({ children, context, getPublicEnv: getPublicEnvFunc }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    getPublicEnvFunc().then(setPublicEnv).catch(setError);
  }, []);

  const refreshPublicEnv = async () => {
    const result = await getPublicEnvFunc();
    if (result?.initialData) {
      setPublicEnv(result);
    }
  };

  const value = useMemo(() => ({ publicEnv, refreshPublicEnv }), [publicEnv, refreshPublicEnv]);

  if (error) return <BrandError />;

  if (!publicEnv) return <BrandLoader />;

  return <PublicEnvContext.Provider value={value}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
