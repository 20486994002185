import { createSelector } from 'reselect';
import { MODULE_NAME, FLAGS } from '@funWithFlags/constants';
import isVariation from '../utils/isVariation';

const getPropFromModule = (state, ...props) => state[MODULE_NAME][props];

const getShowApiCredentialsFlag = createSelector(
  state => getPropFromModule(state, 'flags'),
  flags => isVariation(flags[FLAGS.SHOW_API_CREDENTIALS.KEY]),
);

export default getShowApiCredentialsFlag;
