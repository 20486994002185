import styled from 'styled-components';

const IsNewIndicator = styled.div`
  width: 6px;
  height: 6px;
  border: 3px solid white;
  border-radius: 6px;
  margin-top: 1px;
`;

export default IsNewIndicator;
