import styled from 'styled-components';

const TooltipText = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  text-align: initial;
  font-size: 1.1em;
  line-height: 1.2em;
  justify-content: center;
  padding-left: 12px;
  font-family: ${props => props.theme.fonts.lato};
`;

export default TooltipText;
