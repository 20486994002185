const successAction = ({ type, payload }) => {
  if (payload)
    return {
      type: `${type}_SUCCESS`,
      payload,
    };

  return `${type}_SUCCESS`;
};

export default successAction;
