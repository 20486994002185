import styled from 'styled-components';

const LinkIconContainer = styled.div`
  display: flex;
  margin: 10px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

export default LinkIconContainer;
