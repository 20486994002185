/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { isFAQPage } from '@services/routes';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntlProvider } from 'react-intl';
import { GENERAL_ONLINE_SUPPORT_ID } from '@modules/contact-support/care-center/constants';
import { history } from './configureStore';
import theme from './theme-new';
import { createPostMessageHandler } from './services/hooks/postMessage/postMessageHandler';
import useCareCenter from './hooks/useCareCenter';
import updateCareCenterInstance from './services/hooks/updateCareCenterInstance';
import ErrorHandler from './components/ErrorHandler';
import AppRouter from './AppRouter';
import ContactSupport from './modules/contact-support';
import GlobalStyleSheet from './styles';
import BrandLoader from './components/BrandLoader/BrandLoader';
import { PublicEnvProvider, getPublicEnv } from './contexts/PublicEnv';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App({ context, initialData, messages, store, isAppLoaded }) {
  const countryShortName = initialData?.country.shortName;
  const [contactCenterInstances, setContactCenterInstances] = React.useState([]);
  const { careCenterInstances, addNewInstance } = useCareCenter(initialData);
  const [instances, setCareCenterInstances] = React.useState(careCenterInstances);
  const postMessageHandler = createPostMessageHandler(history, countryShortName);
  React.useEffect(() => {
    setCareCenterInstances(careCenterInstances);
  }, [careCenterInstances]);

  React.useEffect(() => {
    if (initialData?.user && initialData?.onlineSupportUrl) {
      const newInstance = {
        src: initialData.onlineSupportUrl,
        id: GENERAL_ONLINE_SUPPORT_ID,
        triggerAutoOpen: false,
      };
      addNewInstance(newInstance);
    }
  }, []);

  window.addEventListener('message', event => {
    updateCareCenterInstance(event);
    postMessageHandler(event);
  });

  const isFaq = isFAQPage(location.pathname);
  if (!isAppLoaded) return <BrandLoader />;
  return (
    <IntlProvider messages={messages} locale={context.locale} defaultLocale="es">
      <Provider store={store}>
        <PublicEnvProvider getPublicEnv={getPublicEnv}>
          <ThemeProvider theme={theme}>
            <div id="courier-app" style={{ display: 'flex', width: '100%' }}>
              <QueryClientProvider client={queryClient}>
                <GlobalStyleSheet />
                <ConnectedRouter history={history}>
                  <ErrorHandler>
                    <AppRouter
                      initialData={initialData}
                      setCareCenterInstances={setCareCenterInstances}
                      careCenterInstances={instances}
                      setContactCenterInstances={setContactCenterInstances}
                      contactCenterInstances={contactCenterInstances}
                    />
                    {!isFaq && (
                      <ContactSupport
                        setCareCenterInstances={setCareCenterInstances}
                        careCenterInstances={instances}
                        setContactCenterInstances={setContactCenterInstances}
                        contactCenterInstances={contactCenterInstances}
                      />
                    )}
                  </ErrorHandler>
                </ConnectedRouter>
              </QueryClientProvider>
            </div>
          </ThemeProvider>
        </PublicEnvProvider>
      </Provider>
    </IntlProvider>
  );
}

App.propTypes = {
  context: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    customize: PropTypes.string,
    device: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    country: PropTypes.shape({
      id: PropTypes.number.isRequired,
      currencySymbol: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      defaultCity: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    }),
  }).isRequired,
};
