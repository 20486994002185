import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { logError } from '@logs/logger';
import ErrorImg from '@assets/img/Error.jpg';
import RebrandingHeader from '@global/components/RebrandingHeader';
import ErrorSubtitle from './styled-components/ErrorSubtitle';
import ErrorTitle from './styled-components/ErrorTitle';
import TextContainer from './styled-components/TextContainer';
import ImageContainer from './styled-components/ImageContainer';
import Container from './styled-components/Container';
import messages from './messages';

class ErrorHandler extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      showError: true,
    });
    logError({
      error,
      data: errorInfo,
    });
  }

  render() {
    const { showError } = this.state;
    const { children } = this.props;

    if (showError)
      return (
        <div>
          <RebrandingHeader />
          <Container>
            <TextContainer>
              <ErrorTitle>{messages.title.defaultMessage}</ErrorTitle>
              <ErrorSubtitle>{messages.subtitle.defaultMessage}</ErrorSubtitle>
            </TextContainer>
            <ImageContainer>
              <img src={ErrorImg} />
            </ImageContainer>
          </Container>
        </div>
      );

    return children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.node,
};

export default ErrorHandler;
