export const COUNTRY_SHORT_NAME_STAGING = {
  ar: 'https://stg-courier-web-business.pedidosya.com.ar',
  bo: 'https://stg-courier-web-business.pedidosya.com.bo',
  cl: 'https://stg-courier-web-business.pedidosya.cl',
  cr: 'https://stg-courier-web-business.pedidosya.cr',
  do: 'https://stg-courier-web-business.pedidosya.com.do',
  ec: 'https://stg-courier-web-business.pedidosya.com.ec',
  gt: 'https://stg-courier-web-business.pedidosya.com.gt',
  hn: 'https://stg-courier-web-business.pedidosya.com.hn',
  ni: 'https://stg-courier-web-business.pedidosyani.com.ni',
  pa: 'https://stg-courier-web-business.pedidosya.com.pa',
  pe: 'https://stg-courier-web-business.pedidosya.com.pe',
  py: 'https://stg-courier-web-business.pedidosya.com.py',
  sv: 'https://stg-courier-web-business.pedidosyasv.com.sv',
  uy: 'https://stg-courier-web-business.pedidosya.com.uy',
  ve: 'https://stg-courier-web-business.pedidosya.com.ve',
};
export const COUNTRY_SHORT_NAME_LIVE = {
  ar: 'https://courier-web-business.pedidosya.com.ar',
  bo: 'https://courier-web-business.pedidosya.com.bo',
  cl: 'https://courier-web-business.pedidosya.cl',
  cr: 'https://courier-web-business.pedidosya.cr',
  do: 'https://courier-web-business.pedidosya.com.do',
  ec: 'https://courier-web-business.pedidosya.com.ec',
  gt: 'https://courier-web-business.pedidosya.com.gt',
  hn: 'https://courier-web-business.pedidosya.com.hn',
  ni: 'https://courier-web-business.pedidosyani.com.ni',
  pa: 'https://courier-web-business.pedidosya.com.pa',
  pe: 'https://courier-web-business.pedidosya.com.pe',
  py: 'https://courier-web-business.pedidosya.com.py',
  sv: 'https://courier-web-business.pedidosyasv.com.sv',
  uy: 'https://courier-web-business.pedidosya.com.uy',
  ve: 'https://courier-web-business.pedidosya.com.ve',
};
