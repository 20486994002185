import { createSelector } from 'reselect';
import { MODULE_NAME, FLAGS } from '@funWithFlags/constants';
import isVariation from '../utils/isVariation';

const getPropFromModule = (state, ...props) => state[MODULE_NAME][props];

const getNewRebrandingFlag = createSelector(
  (state) => getPropFromModule(state, 'flags'),
  (flags) => isVariation(flags[FLAGS.UX_REBRANDING.KEY]),
);

export default getNewRebrandingFlag;
