import React, { Suspense, lazy } from 'react';
import ROUTES from '@app/services/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BrandLoader } from '@appscore/web-components';
const Login = lazy(() => import('@user/containers/Login'));
const TermsAndConditions = lazy(() => import('@home/containers/TermsAndConditions'));

export default function PublicRoutes({ genericError, setGenericError }) {
  return (
    <Suspense fallback={<BrandLoader />}>
      <Switch>
        <Route
          path={ROUTES.LOGIN}
          exact
          render={props => (
            <Login genericError={genericError} setGenericError={setGenericError} {...props} />
          )}
        />
        <Route path={ROUTES.REGISTRY} render={props => <Login modeRegistry {...props} />} />
        <Route
          path={ROUTES.TERMS_AND_CONDITIONS}
          exact
          render={props => <TermsAndConditions {...props} />}
        />
        <Redirect to={ROUTES.LOGIN} />
      </Switch>
    </Suspense>
  );
}
