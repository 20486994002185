export const DO_LOGIN = 'app/user/DO_LOGIN';
export const DO_LOGOUT = 'app/user/DO_LOGOUT';
export const GET_BRANCHES = 'app/user/GET_BRANCHES';
export const CREATE_CREDENTIALS = 'app/user/CREATE_CREDENTIALS';
export const RESET_PASSWORD = 'app/user/RESET_PASSWORD';
export const RESET_STATE = 'app/user/RESET_STATE';
export const RESET_ONBOARDING = 'app/user/RESET_ONBOARDING';
export const GET_ROLES = 'app/user/GET_ROLES';
export const GET_USER_TYPE = 'app/user/GET_USER_TYPE';
export const SAVE_CONTACT_DATA = 'app/user/SAVE_CONTACT_DATA';
export const GET_CONTACT_DATA = 'app/user/GET_CONTACT_DATA';
export const SAVE_ADDRESS_BILLING = 'app/user/SAVE_ADDRESS_BILLING';
export const SAVE_BUSINESS_DATA = 'app/user/SAVE_BUSINESS_DATA';
export const GET_STATUS_PARTNER = 'app/user/GET_STATUS_PARTNER';
export const SEND_CREATION_PARTNER = 'app/user/SEND_CREATION_PARTNER';
export const RESET_ERRORS_ON_CREATE_PARTNER = 'app/user/RESET_ERRORS_ON_CREATE_PARTNER';
export const GET_POSSIBLE_PARTNERS_TO_DO_LOGIN = 'app/user/GET_POSSIBLE_PARTNERS_TO_DO_LOGIN';
export const IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN =
  'app/user/IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN';
