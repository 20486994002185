import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import pushPageAction from '@global/actions/pushPage';

import ROUTES from './index';

export const navigateToAddressEditor = pushPage => id => {
  pushPage(`${ROUTES.ADDRESS_EDIT}/${id}`);
};

export const navigateToAddressCreator = pushPage => () => {
  pushPage(`${ROUTES.ADDRESS_EDIT}/`);
};

export const navigateToMyOrders = pushPage => () => {
  pushPage(ROUTES.MY_ORDERS);
};

export const navigateToMyAddresses = pushPage => () => {
  pushPage(`${ROUTES.MY_ACCOUNT.ROOT}${ROUTES.MY_ACCOUNT.MY_ADDRESSES}`);
};

export const navigateToMyCredentials = pushPage => () => {
  pushPage(`${ROUTES.MY_ACCOUNT.ROOT}${ROUTES.MY_ACCOUNT.CREDENTIALS}`);
};

export const navigateToMyProfile = pushPage => () => {
  pushPage(`${ROUTES.MY_ACCOUNT.ROOT}${ROUTES.MY_ACCOUNT.MY_PROFILE}`);
};

const navigators = p => ({
  navigateToAddressEditor: navigateToAddressEditor(p),
  navigateToAddressCreator: navigateToAddressCreator(p),
  navigateToMyAddresses: navigateToMyAddresses(p),
  navigateToMyCredentials: navigateToMyCredentials(p),
  navigateToMyProfile: navigateToMyProfile(p),
});

const connector = connect(
  null,
  { pushPage: pushPageAction },
);

export const withNavigator = WrappedComponent =>
  withRouter(
    connector(props => (
      <WrappedComponent {...navigators(props.pushPage)} {...props} />
    )),
  );
