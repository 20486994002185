import { createSelector } from 'reselect';
import { USER_LOGGED } from '@user/constants';
import { isEmptyObject } from '@utils/object';

import getPropFromModule from './getPropFromModule';

const getIsUserLogged = createSelector(
  (state) => getPropFromModule(state, USER_LOGGED),
  (userLogged) => !isEmptyObject(userLogged),
);

export default getIsUserLogged;
