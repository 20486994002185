import successAction from '@global/actions/successAction';
import failAction from '@global/actions/failAction';
import service from '@services/UserService';
import { logError } from '@logs/logger';
import { GENERIC_ERROR } from '@utils/genericError';
const {
  GET_POSSIBLE_PARTNERS_TO_DO_LOGIN,
  IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN,
} = require('../actionTypes');

const type = GET_POSSIBLE_PARTNERS_TO_DO_LOGIN;

const fetchPossiblePartnersToDoLogin = partnerName => async dispatch => {
  try {
    dispatch({ type });
    dispatch({ type: IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN, payload: true });
    const { success, data, error } = await service.fetchPartners(partnerName);
    if (!success) {
      dispatch(
        failAction({
          type,
          error,
        }),
      );
      return error;
    }

    dispatch(
      successAction({
        type,
        payload: data,
      }),
    );
    return data;
  } catch (e) {
    dispatch(
      failAction({
        type,
        error: e || GENERIC_ERROR,
      }),
    );
    logError({
      message: `Exception on Action (dispatch) Dispatching failure action: ${type}`,
      error: e || GENERIC_ERROR,
    });
    return null;
  } finally {
    dispatch({ type: IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN, payload: false });
  }
};

export default fetchPossiblePartnersToDoLogin;
