export const MODULE_NAME = 'user';
export const IS_DOING_LOGIN = 'isDoingLogin';
export const ERROR_MESSAGE = 'errorMessage';
export const USER_LOGGED = 'userLogged';
export const IS_DOING_LOGOUT = 'isDoingLogout';
export const FROM_LOGOUT = 'fromLogout';
export const IS_FETCHING_BRANCHES = 'isFetchingBranches';
export const BRANCHES = 'branches';
export const USERS_HAS_FETCHED = 'usersHasFetched';
export const SELECTED_PARTNER = 'selectedPartner';
export const COUNTRY = 'country';
export const IS_CREATING_CREDENTIALS = 'isCreatingCredentials';
export const CITY = 'city';
export const ONBOARDING = 'onboarding';
export const CONTACT_DATA = 'contactData';
export const ADDRESS_BILLING = 'addressBilling';
export const BUSINESS_DATA = 'businessData';
export const IS_SENDING_CREATION_PARTNER = 'isSendingCreationPartner';
export const ERROR_GENERIC_ON_CREATE_PARTNER = 'errorGenericOnCreatePartner';
export const ERROR_TAXNUMBER_DUPLICATE = 'errorTaxNumberDuplicate';
export const ERROR_TAXNUMBER_INVALID = 'errorTaxnumberInvalid';
export const ERROR_COMERCIAL_NAME_DUPLICATE = 'errorComercialNameDuplicate';
export const PARTNER_WAS_CREATED = 'partnerWasCreated';
export const ROLES = 'roles';
export const FETCHING_USER_TYPE = 'fetchingUserType';
export const IS_GETTING_STATUS_PARTNER = 'isGettingStatusPartner';
export const MY_PROFILE_DATA = 'myProfile';
export const MY_CONTACT_DATA = 'contactData';
export const POSSIBLE_PARTNERS_TO_DO_LOGIN = 'possiblePartnersToDoLogin';
export const IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN = 'isFetchingPossiblePartnersToDoLogin';
export const LOGIN_FROM = isLocal =>
  isLocal
    ? encodeURIComponent('Y291cmllci1idXNpbmVzcy13ZWItbG9jYWw=')
    : encodeURIComponent('Y291cmllci1idXNpbmVzcy13ZWI=');

export const LOGIN_TYPE = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
  EMAIL: 'email',
  PHONE: 'phone',
};
export const TYPE_ERROR_CREATE_PARTNER = {
  TAXNUMBER_DUPLICATE: '/error/tax_number_exists',
  TAXNUMBER_INVALID: '/error/tax_number_invalid',
  COMMERCIAL_NAME_DUPLICATE: '/error/partner_already_exists',
};
export const RESPONSE_PARTNER = {
  PARTNER_NOT_FOUND: 404,
  PARTNER_SUCCESS: 'ONLINE',
  PARTNER_IN_PROGRESS: 'IN_PROGRESS',
  PARTNER_WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
};
