import styled from 'styled-components';

const Pipe = styled.div`
  justify-content: center;
  height: 2vh;
  margin-right: 5px;
  border-left: 1px solid ${props => props.theme.colors.elementLine};
`;

export default Pipe;
