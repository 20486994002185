import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'src.CareCenterIframe.CloseModalWarning.tilte',
    defaultMessage: '¿Quieres cerrar esta consulta?',
  },
  subtitle: {
    id: 'src.CareCenterIframe.CloseModalWarning.subtitle',
    defaultMessage: 'Al cerrarla, reiniciarás la sesión.',
  },
  cancelButton: {
    id: 'src.CareCenterIframe.CloseModalWarning.cancelButton',
    defaultMessage: 'Cancelar',
  },
  closeButton: {
    id: 'src.CareCenterIframe.CloseModalWarning.closeButton',
    defaultMessage: 'Cerrar',
  },
});
