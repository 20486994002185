import { createSelector } from 'reselect';
import getUserPermission from '@user/selectors/getUserPermission';
import { ROL_SUPERVISOR } from '@utils/constants';

const getIsSupervisor = createSelector(
  getUserPermission,
  userPermission => userPermission === ROL_SUPERVISOR,
);

export default getIsSupervisor;
