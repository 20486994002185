import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: none;
  width: ${props => (props.width ? props.width : '120px')};
  height: ${props => (props.height ? props.height : '40px')};
  border-radius: 6px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  font-family: ${props => props.theme.fonts.lato};
  background-color: ${props =>
    props.primary ? props.theme.colors.Main : props.theme.colors.White};
  color: ${props => (props.primary ? props.theme.colors.White : props.theme.colors.Main)};
  opacity: ${props => props.isLoading && '0.8'};
  font-size: ${props => (props.fontSize ? props.fontSize : '0.9em')};
  font-weight: bold;
  padding: 10px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: ${props => (props.margin ? props.margin : '23px 0')};
  :focus {
    outline: none;
  }
  cursor: ${props => (props.disabled ? `default` : `pointer`)};
  ${props => props.disabled && `opacity: 0.3;`};
`;

export default Button;
