import React from 'react';
import Logo from '@components/Logo';
import RebrandingNavLinks from '@global/containers/RebrandingNavLinks';
import ROUTES from '@services/routes';
import PropTypes from 'prop-types';
import HeaderContainer from './styled-components/HeaderContainer';
import LogoContainer from './styled-components/LogoContainer';

const RebrandingHeader = ({ disabled, setCareCenterInstances, userCanCreateOrdersInBulk }) => (
  <HeaderContainer disabled={disabled}>
    <LogoContainer to={ROUTES.HOME}>
      <Logo newRebrandingFlag isHeader />
    </LogoContainer>
    <RebrandingNavLinks
      setCareCenterInstances={setCareCenterInstances}
      newRebrandingFlag
      userCanCreateOrdersInBulk={userCanCreateOrdersInBulk}
    />
  </HeaderContainer>
);

RebrandingHeader.propTypes = {
  disabled: PropTypes.bool,
  userCanCreateOrdersInBulk: PropTypes.bool.isRequired,
  setCareCenterInstances: PropTypes.func,
};

export default RebrandingHeader;
