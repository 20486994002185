import { createSelector } from 'reselect';
import { FROM_LOGOUT } from '@user/constants';

import getPropFromModule from './getPropFromModule';

const getIsFromLogout = createSelector(
  state => getPropFromModule(state, FROM_LOGOUT),
  fromLogout => fromLogout,
);

export default getIsFromLogout;
