import React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@components/Button';
import PropTypes from 'prop-types';
import Container from './styled-components/Container';
import ButtonsContainer from './styled-components/ButtonsContainer';
import Title from './styled-components/Title';
import Subtitle from './styled-components/Subtitle';
import Overlay from './styled-components/Overlay';

import messages from './messages';

const CloseModalWarning = ({ onClose, onCancel, intl: { formatMessage } }) => (
  <Overlay>
    <Container>
      <Title>{formatMessage(messages.title)}</Title>
      <Subtitle>{formatMessage(messages.subtitle)}</Subtitle>
      <ButtonsContainer>
        <Button innerText={formatMessage(messages.cancelButton)} onClick={onCancel} width="45%" />
        <Button
          innerText={formatMessage(messages.closeButton)}
          primary
          onClick={onClose}
          width="45%"
        />
      </ButtonsContainer>
    </Container>
  </Overlay>
);

CloseModalWarning.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default injectIntl(CloseModalWarning);
