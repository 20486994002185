import { createSelector } from 'reselect';
import { POSSIBLE_PARTNERS_TO_DO_LOGIN } from '@user/constants';

import getPropFromModule from './getPropFromModule';

const getPossiblePartnersToDoLogin = createSelector(
  state => getPropFromModule(state, POSSIBLE_PARTNERS_TO_DO_LOGIN),
  possiblePartnersToDoLogin => possiblePartnersToDoLogin,
);

export default getPossiblePartnersToDoLogin;
