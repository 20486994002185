import React from 'react';
import PropTypes from 'prop-types';
import LinkIconContainer from './styled-components/LinkIconContainer';
import LinkLabel from './styled-components/LinkLabel';
import LinkIcon from './styled-components/LinkIcon';

const HeaderLinkWithIcon = ({ label, onClick, linkIcon, disabled, landing, newRebrandingFlag }) => (
  <LinkIconContainer onClick={() => !disabled && onClick()} disabled={disabled}>
    <LinkIcon src={linkIcon} />
    <LinkLabel landing={landing} disabled={disabled} newRebrandingFlag={newRebrandingFlag}>
      {label}
    </LinkLabel>
  </LinkIconContainer>
);

HeaderLinkWithIcon.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  linkIcon: PropTypes.any,
  disabled: PropTypes.bool,
  landing: PropTypes.bool,
  newRebrandingFlag: PropTypes.bool.isRequired,
};

export default HeaderLinkWithIcon;
