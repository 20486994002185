import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClosableTooltip from '@components/ClosableTooltip/ClosableTooltip';
import { save, get } from '@services/localStorage';

import { CLASSIC_USER_INTEGRATION_TOOLTIP } from './constants';

const ClassicUserTooltip = ({ text }) => {
  const noticeTooltipShown = shownDate =>
    save(CLASSIC_USER_INTEGRATION_TOOLTIP, JSON.stringify({ shownDate }));

  const [tooltipShown, setTooltipShown] = useState(get(CLASSIC_USER_INTEGRATION_TOOLTIP));

  const handleClose = () => {
    const shownDate = new Date();
    noticeTooltipShown(shownDate);
    setTooltipShown({ shownDate });
  };

  if (!tooltipShown) {
    return <ClosableTooltip text={text} onClose={() => handleClose()} />;
  }
  return <div />;
};

ClassicUserTooltip.propTypes = {
  text: PropTypes.string,
};

export default ClassicUserTooltip;
