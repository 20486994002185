import { getEnv, isProd, isLocal, isDev } from '@utils/environment';
import { Flags } from './models/Flags';
import { FLAGS_KEYS } from './constants';
const TOKENS = {
  local: '5d5ab78c-d233-43f7-b692-5f10c72c21cf',
  dev: '5d5ab78c-d233-43f7-b692-5f10c72c21cf',
  stg: 'bb63f68b-9630-4442-a519-69693eec97d3',
  live: 'f0926b79-edcd-4aa2-8326-66374c49317a',
  test: '',
};

const URL = {
  local: 'https://fwf-client-api.dev.peja.co',
  dev: 'https://fwf-client-api.dev.peja.co',
  stg: 'http://fwf-client-api-web.stg.peja.co',
  live: 'http://fwf-client-api-web.live.peja.co',
  test: '',
};

let FWFInstance;

export function getFWFToken(environment) {
  if (TOKENS[environment] === undefined) return TOKENS.local;
  return TOKENS[environment];
}

export function getFWFUrl(environment) {
  if (URL[environment] === undefined) return URL.local;
  return URL[environment];
}

const FLAGS_UPDATED_EVENT_NAME = '@fwf/FLAGS_UPDATED_IN_BACKGROUND';
const CONTEXT_UPDATED_EVENT_NAME = '@fwf/RELEVANT_CONTEXT_CHANGE';
// change manualy to true to enable SDK debug mode

const fwfParams = () => ({
  environmentToken: getFWFToken(getEnv()),
  flagTimeToLive: isProd() ? 5 : 1,
  region: 'us',
  localFwfUrl: getFWFUrl(getEnv()),
  debugMode: isLocal() || isDev(),
});

let initializationPromise;

function importSdk() {
  return import(/* webpackChunkName: "deliveryhero-fwf-sdk" */ '@deliveryhero/fwf-sdk-javascript');
}

export async function initializeInstance(fwfContext, callback) {
  initializationPromise = new Promise(async (resolve, reject) => {
    try {
      const user = getUser({ ...fwfContext });
      const { default: FWFMain } = await importSdk();
      const params = fwfParams();
      FWFInstance = new FWFMain({ ...params, user });
      await FWFInstance.updateUserAttributes(user);
      await FWFInstance.subscribeFeatures(FLAGS_KEYS);
      const flagsVariations = await FWFInstance.getVariations(FLAGS_KEYS);
      const flagVariation = Flags(flagsVariations);
      callback(flagVariation);
      await FWFInstance.subscribeObserver(response => {
        if (
          response.type === FLAGS_UPDATED_EVENT_NAME ||
          response.type === CONTEXT_UPDATED_EVENT_NAME
        ) {
          const flags = Flags(response.data);
          callback(flags);
        }
      });
      resolve(FWFInstance);
    } catch (error) {
      reject(error);
    }
  });

  return initializationPromise;
}

export function getInstance() {
  if (!FWFInstance) throw new Error('must call initializeInstance before get one');
  return FWFInstance;
}

export function getUser({ sessionId, userData = {}, contextData = {} }) {
  let user = {
    ...contextData,
  };
  if (sessionId) user.googleClientId = sessionId;
  const { userId, userEmail, ...userDataRest } = userData;
  if (userId) user.userId = userId.toString();
  if (userEmail) user.email = userEmail;
  if (userId || userEmail) user = { ...user, ...userDataRest };
  return user;
}

export async function updateUser({ sessionId, userData, contextData = {}, instance }) {
  const user = getUser({ sessionId, userData, contextData });
  return instance.updateUserAttributes(user);
}
