/* eslint-disable no-param-reassign */
import produce from 'immer';
import successAction from '@global/actions/successAction';
import failAction from '@global/actions/failAction';
import ModuleStateModel from './defaultState';
import * as modelKeys from './constants';
import * as actions from './actionTypes';

const initialState = ModuleStateModel;

function locationModuleReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actions.GOOGLE_MAP_LOADED: {
        draft[modelKeys.IS_GOOGLE_MAPS_LOADED] = true;
        break;
      }

      case actions.CLEAR_ADDRESSES: {
        draft[modelKeys.COORDINATES] = [];
        draft[modelKeys.HAS_FETCHED_COORDINATES] = false;
        break;
      }

      case actions.GET_COORDINATES: {
        draft[modelKeys.HAS_FETCHED_COORDINATES] = false;
        draft[modelKeys.ERROR_MESSAGE] = '';
        break;
      }

      case actions.SET_COORDINATES: {
        draft[modelKeys.COORDINATES] = action.payload;
        break;
      }

      case successAction({ type: actions.GET_COORDINATES }): {
        draft[modelKeys.HAS_FETCHED_COORDINATES] = true;
        draft[modelKeys.ERROR_MESSAGE] = '';
        draft[modelKeys.COORDINATES] = action.payload;
        break;
      }

      case successAction({ type: actions.GET_AREA_ID }): {
        const { data } = action.payload;
        draft[modelKeys.AREA_ID] = data[0].area_id;
        break;
      }

      case failAction({ type: actions.GET_COORDINATES }): {
        draft[modelKeys.HAS_FETCHED_COORDINATES] = true;
        draft[modelKeys.ERROR_MESSAGE] = action.error;
        break;
      }

      case (actions.STREET_TYPES, failAction({ type: actions.STREET_TYPES })): {
        draft[modelKeys.STREET_TYPES] = [];
        break;
      }

      case successAction({ type: actions.STREET_TYPES }): {
        draft[modelKeys.STREET_TYPES] = action.payload;
        break;
      }

      default:
        return draft;
    }
  });
}

export default locationModuleReducer;
