import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import produce from 'immer';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { isProd } from '@utils/environment';
import { setBufferActions } from '@logs/logger';
import createReducer from './reducers';
export const history = createBrowserHistory();

export default function configureStore({ initialState, axios }) {
  return configureAppStore(initialState, axios);
}

function configureAppStore(initialState = {}, axiosInstance) {
  const composeEnhancers =
    !isProd() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: true })
      : compose;

  const immerState = produce(initialState, (draftState) => draftState);

  const bufferActions = () => (store) => (next) => (action) => {
    setBufferActions(action);
    next(action);
  };

  const enhancers = [
    applyMiddleware(thunk.withExtraArgument(axiosInstance)),
    applyMiddleware(routerMiddleware(history)),
    applyMiddleware(bufferActions()),
  ];
  return createStore(createReducer(history), immerState, composeEnhancers(...enhancers));
}
