import { createSelector } from 'reselect';
import { ORDERS } from '@orders-bulk/constants';
import getPropFromModule from './getPropFromModule';

const getBulkOrders = createSelector(
  state => getPropFromModule(state, ORDERS),
  orders => orders.filter(x => !x.successfullyCreated) || [],
);

export default getBulkOrders;
