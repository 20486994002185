import React from 'react';
import styled from 'styled-components';

import animatedLoader from '@assets/icons/brand-loader.png';

const Container = styled.div`
  display: flex;
  height: ${props => (props.maxSize ? '100vh' : '')};
  position: relative;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const StyledImage = styled.img`
  width: ${props => (props.customWidth ? props.customWidth : '48px')};
`;

function BrandLoader({ maxSize = true, customWidth }) {
  return (
    <Container maxSize={maxSize}>
      <StyledImage customWidth={customWidth} src={animatedLoader} alt="animatedLoader" />
    </Container>
  );
}

export default BrandLoader;
