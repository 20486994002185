import { logEvent, logError } from '@logs/logger';
import { NOT_SET } from '@app/utils/constants/tracking.constant';

let instance;

export const setInstance = async (tracker, initialData) => {
  if (!instance) {
    instance = {
      tracker,
      initialData,
    };
  }
  return instance;
};

export default function track(event, payload, { sendToLogs = false } = {}) {
  try {
    const data = { ...payload };
    const { initialData } = instance;

    if (!payload.time || payload.time === NOT_SET) {
      data.time = new Date(Date.now()).toLocaleString();
    }
    if (!data.partnerId) {
      data.partnerId = initialData.user?.partnerId || NOT_SET;
    }
    if (!data.userId) {
      data.userId = initialData.user?.id || NOT_SET;
    }
    if (!data.countryHostName) {
      data.countryHostName = initialData.country?.name || NOT_SET;
    }
    if (!data.countryShortName) {
      data.countryShortName = initialData.country?.shortName || NOT_SET;
    }

    instance.tracker.track(event, data);

    if (sendToLogs) {
      logEvent(event, { data: payload });
    }
  } catch (error) {
    logError({
      message: `[TRACKING][index.js] Error on Track func trying to track: ${event}`,
      error,
      data: payload,
    });
  }
}
