/* eslint-disable no-param-reassign */
import produce from 'immer';
import successAction from '@global/actions/successAction';
import failAction from '@global/actions/failAction';
import * as actions from './actionTypes';
import ModuleStateModel from './defaultState';
import * as modelKeys from './constants';

const initialState = ModuleStateModel;

function globalModuleReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actions.APP_INIT: {
        draft[modelKeys.IS_FETCHING_APP_INIT] = true;
        break;
      }

      case successAction({ type: actions.APP_INIT }): {
        const { payload } = action;
        draft[modelKeys.IS_FETCHING_APP_INIT] = true;
        draft[modelKeys.SELECTED_COUNTRY] = payload.country;
        draft[modelKeys.SESSION_ID] = payload.sessionId;
        draft[modelKeys.CAPTCHA_SITE_KEY] = payload.captchaSiteKey;

        const client = new Date();
        const server = new Date(payload.timeNow);
        const diffInMinutes = (server - client) / 1000 / 60;
        draft[modelKeys.DIFF_TIME_IN_MINUTES] = Math.round(diffInMinutes);
        break;
      }

      case failAction({ type: actions.APP_INIT }): {
        draft[modelKeys.IS_FETCHING_APP_INIT] = false;
        break;
      }

      case actions.INCREASE_CURRENT_TOASTS: {
        draft[modelKeys.CURRENT_TOAST] = state[modelKeys.CURRENT_TOAST] + 1;
        break;
      }

      case actions.DECREASE_CURRENT_TOASTS: {
        draft[modelKeys.CURRENT_TOAST] = state[modelKeys.CURRENT_TOAST] - 1;
        break;
      }

      case actions.SELECT_PARTNER: {
        draft[modelKeys.SELECTED_PARTNER] = action.payload;
        break;
      }

      default:
        return draft;
    }
  });
}

export default globalModuleReducer;
