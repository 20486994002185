import styled from 'styled-components';

const Overlay = styled.p`
  position: absolute;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  height: 100%;
  width: 100%;
  border-radius: 6px 6px 0px 0px;
  margin: 0;
`;

export default Overlay;
