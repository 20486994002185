import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { trackHeaderClick, trackSupport, trackLogout } from '@tracking/actions/header';
import pushPage from '@global/actions/pushPage';
import updateRouteSelected from '@orders-multiple/actions/updateRouteSelected';
import getStopNavigation from '@orders-multiple/selectors/getStopNavigation';
import getIsUserLogged from '@user/selectors/getIsUserLogged';
import doLogout from '@user/actions/doLogout';
import getUserCanCreateOrder from '@user/selectors/getUserCanCreateOrder';
import getUserLogged from '@user/selectors/getUserLogged';
import isSendingOrders from '@orders-bulk/selectors/getIsSendingOrders';
import isValidatingOrders from '@orders-bulk/selectors/getOrdersAreValidating';
import isSupervisor from '@user/selectors/getIsSupervisor';
import isClassicUser from '@user/selectors/getIsClassicUser';
import showApiCredentialsFlag from '@funWithFlags/selectors/getShowApiCredentialsFlag';
import getIsPartnerIntegrated from '@funWithFlags/selectors/getIsPartnerIntegrated';
import getTotalPartners from '@app/modules/user/selectors/getTotalPartners';
import getSelectedPartner from '@app/modules/user/selectors/getSelectedPartner';
import NavLinks from './NavLinks';

const mapStateToProps = createStructuredSelector({
  isUserLogged: getIsUserLogged,
  isPartnerIntegrated: getIsPartnerIntegrated,
  userCanCreateOrder: state => getUserCanCreateOrder(state),
  isValidatingOrders,
  isSendingOrders,
  isSupervisor,
  showApiCredentialsFlag,
  isClassicUser,
  stopNavigation: getStopNavigation,
  userLogged: getUserLogged,
  totalPartners: getTotalPartners,
  selectedPartner: getSelectedPartner,
});

const mapDispatchToProps = {
  pushPage,
  doLogout,
  trackHeaderClick,
  trackSupport,
  trackLogout,
  updateRouteSelected,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavLinks));
