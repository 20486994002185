export class QueryString {
  constructor(url) {
    if (url) this.urlParams = new URLSearchParams(url);
    else this.urlParams = new URLSearchParams();
  }

  get(key) {
    const value = this.urlParams.get(key);
    if (value === 'undefined') return undefined;
    if (value === 'null') return null;
    return value;
  }

  append(name, value) {
    this.urlParams.append(name, value);
  }

  toString() {
    return this.urlParams.toString();
  }
}
