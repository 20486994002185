import LogoIcon from '@assets/icons/logo.svg';
import LogoIconRebranding from '@assets/icons/logoRebranding.svg';
import LogoHeader from '@assets/icons/logoHeader.svg';
import phone from '@assets/icons/phone-BranchCard.svg';
import phoneNew from '@assets/icons/phone-BranchCard-new.svg';

import theme from '../theme';
import themeNew from '../theme-new';

export const getThemeFile = themeFlag => {
  if (themeFlag) return themeNew;
  return theme;
};

const getBaseIcon = (newRebrandingFlag, isHeader) => {
  if (isHeader) {
    return newRebrandingFlag ? LogoHeader : LogoIcon;
  }
  return newRebrandingFlag ? LogoIconRebranding : LogoIcon;
};

const getPhone = newRebrandingFlag => (newRebrandingFlag ? phoneNew : phone);

export const getAppLogo = (newRebrandingFlag, isHeader) => {
  return getBaseIcon(newRebrandingFlag, isHeader);
};

export const getPhoneIcon = newRebrandingFlag => {
  return getPhone(newRebrandingFlag);
};
