import { getCreateOrderIframeSrc } from '@services/URL';
import { LOCAL_ENVIRONMENT, DEV_ENVIRONMENT, getEnv } from '@utils/environment';

const allowedMessagers = [];

const injectIframeUrl = countryShortName =>
  allowedMessagers.push(getCreateOrderIframeSrc(countryShortName));

const getAllowedMessagers = countryShortName => {
  if (allowedMessagers.length) return allowedMessagers;

  // Else
  if (getEnv() === LOCAL_ENVIRONMENT || getEnv() === DEV_ENVIRONMENT)
    allowedMessagers.push('http://localhost:3001');
  injectIframeUrl(countryShortName);

  return allowedMessagers;
};

export { getAllowedMessagers };
