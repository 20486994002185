import { DO_LOGOUT } from '@user/actionTypes';
import { logout } from '@services/user/logout';
import successAction from '@global/actions/successAction';
import failAction from '@global/actions/failAction';
import pushPage from '@global/actions/pushPage';
import ROUTES from '@services/routes';
import { GENERIC_ERROR } from '@utils/genericError';
import dropCareCenterInstances from '@services/hooks/dropInstances';
import resetUserModule from '@user/actions/resetUserModule';
import { logError, updateUser } from '@logs/logger';
import getSessionId from '../../global/selectors/getSessionId';

const type = DO_LOGOUT;

const doLogout = route => async (dispatch, getState, axios) => {
  try {
    const sessionId = getSessionId(getState());
    dispatch({ type });

    const { success, error } = await logout({
      axios,
    });

    if (!success) {
      dispatch(failAction({ type, error }));
    } else {
      dispatch(
        successAction({
          type,
          payload: {},
        }),
      );
      updateUser(sessionId, {});
      dispatch(resetUserModule());
      dropCareCenterInstances();
      dispatch(pushPage(route || ROUTES.LOGIN));
    }
  } catch (e) {
    dispatch(
      failAction({
        type,
        error: e || GENERIC_ERROR,
      }),
    );
    logError({
      message: `Exception on Action (dispatch) Dispatching failure action: ${type}`,
      error: e || GENERIC_ERROR,
    });
  }
};

export default doLogout;
