import React from 'react';
import minimize from '@assets/icons/minimize-arrow.svg';
import close from '@assets/icons/close-white.svg';
import IframeContainer from '../care-center/styled-components/IframeContainer';
import Iframe from '../care-center/styled-components/IFrame';
import Header from '../care-center/styled-components/Header';
import HeaderButton from '../care-center/styled-components/HeaderButton';
import ButtonsContainer from '../care-center/styled-components/ButtonsContainer';

const ContactCenter = ({ instance, index, setContactCenterInstances, contactCenterInstances }) => {
  const ref = React.createRef();
  const [isVisible, setIsVisible] = React.useState(
    typeof instance.triggerAutoOpen === 'boolean' ? instance.triggerAutoOpen : true,
  );
  const [arrowEffect, setArrowEffect] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => setArrowEffect(isVisible), 300);
  }, [isVisible]);

  React.useEffect(() => {
    if (typeof instance.triggerAutoOpen === 'boolean') {
      setIsVisible(instance.triggerAutoOpen);
    }
  }, [instance]);

  const handleOnClose = () => {
    const instancesCopy = [...contactCenterInstances];
    instancesCopy.splice(index, 1);

    const findIndex = contactCenterInstances.findIndex(x => x.id === instance.id);

    if (findIndex !== -1) {
      const instancesToSave = [...contactCenterInstances];
      instancesToSave.splice(findIndex, 1);
      setContactCenterInstances(instancesToSave);
    }
  };

  return (
    <IframeContainer isVisible={isVisible} index={index}>
      <Header onClick={() => !isVisible && setIsVisible(true)}>
        {instance.id}
        <ButtonsContainer>
          <HeaderButton
            isVisible={arrowEffect}
            src={minimize}
            onClick={() => setIsVisible(!isVisible)}
          />
          {isVisible && <HeaderButton src={close} onClick={handleOnClose} />}
        </ButtonsContainer>
      </Header>
      <Iframe
        ref={ref}
        name={instance.id}
        src={instance.src}
        srcDoc={instance.srcDoc}
        title="contact-center"
        width="380px"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        height="514px"
      />
    </IframeContainer>
  );
};

export default ContactCenter;
