import { useState, useEffect, useCallback } from 'react';

import addCareCenterInstance from '@services/hooks/addCareCenterInstance';
import getCareCenterInstances from '@services/hooks/getCareCenterInstances';
import deleteCareCenterInstance from '@services/hooks/deleteCareCenterInstance';

function useCareCenter() {
  const [careCenterInstances, setCareCenterInstances] = useState([]);

  const update = useCallback(() => {
    (async () => {
      const instances = getCareCenterInstances() || [];
      setCareCenterInstances(instances);
    })();
  }, []);

  useEffect(update, []);

  const addNewInstance = useCallback(
    async value => {
      addCareCenterInstance(value);
      update();
    },
    [update],
  );

  const deleteInstance = useCallback(
    async value => {
      await deleteCareCenterInstance(value);
      update();
    },
    [update],
  );
  return { careCenterInstances, addNewInstance, deleteInstance };
}

export default useCareCenter;
