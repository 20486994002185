import { createSelector } from 'reselect';
import { SELECTED_PARTNER } from '@global/constants';

import getPropFromModule from './getPropFromModule';

const getSelectedPartner = createSelector(
  state => getPropFromModule(state, SELECTED_PARTNER),
  selectedPartner => selectedPartner,
);

export default getSelectedPartner;
