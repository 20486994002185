/* eslint-disable no-param-reassign,consistent-return */
import failAction from '@global/actions/failAction';
import successAction from '@global/actions/successAction';
import produce from 'immer';
import * as actions from './actionTypes';
import * as modelKeys from './constants';
import ModuleStateModel from './defaultState';

const initialState = ModuleStateModel;

// eslint-disable-next-line default-param-last
function userModuleReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case actions.DO_LOGIN: {
        draft[modelKeys.IS_DOING_LOGIN] = true;
        draft[modelKeys.ERROR_MESSAGE] = '';
        break;
      }

      case actions.CREATE_CREDENTIALS: {
        draft[modelKeys.IS_CREATING_CREDENTIALS] = true;
        break;
      }

      case successAction({ type: actions.CREATE_CREDENTIALS }): {
        draft[modelKeys.IS_CREATING_CREDENTIALS] = false;
        break;
      }

      case failAction({ type: actions.CREATE_CREDENTIALS }): {
        draft[modelKeys.IS_CREATING_CREDENTIALS] = false;
        break;
      }

      case actions.RESET_STATE: {
        return initialState;
      }

      case actions.RESET_ONBOARDING: {
        draft[modelKeys.ONBOARDING] = initialState[modelKeys.ONBOARDING];
        break;
      }

      case successAction({ type: actions.DO_LOGIN }): {
        const { payload } = action;
        draft[modelKeys.IS_DOING_LOGIN] = false;
        draft[modelKeys.ERROR_MESSAGE] = '';
        draft[modelKeys.USER_LOGGED] = payload;
        break;
      }

      case failAction({ type: actions.DO_LOGIN }): {
        draft[modelKeys.IS_DOING_LOGIN] = false;
        draft[modelKeys.SELECTED_PARTNER] = '';
        draft[modelKeys.ERROR_MESSAGE] = action.error;
        break;
      }

      case actions.DO_LOGOUT: {
        draft[modelKeys.IS_DOING_LOGOUT] = true;
        break;
      }

      case successAction({ type: actions.DO_LOGOUT }): {
        draft[modelKeys.IS_DOING_LOGOUT] = false;
        draft[modelKeys.FROM_LOGOUT] = true;
        draft[modelKeys.USER_LOGGED] = {};
        draft[modelKeys.SELECTED_PARTNER] = '';
        break;
      }

      case failAction({ type: actions.DO_LOGOUT }): {
        draft[modelKeys.IS_DOING_LOGOUT] = false;
        break;
      }

      case actions.GET_BRANCHES: {
        draft[modelKeys.IS_FETCHING_BRANCHES] = true;
        draft[modelKeys.ERROR_MESSAGE] = '';
        break;
      }

      case successAction({ type: actions.GET_BRANCHES }): {
        draft[modelKeys.IS_FETCHING_BRANCHES] = false;
        draft[modelKeys.ERROR_MESSAGE] = '';
        draft[modelKeys.BRANCHES] = action.payload.branches;
        break;
      }

      case failAction({ type: actions.GET_BRANCHES }): {
        draft[modelKeys.IS_FETCHING_BRANCHES] = false;
        draft[modelKeys.ERROR_MESSAGE] = action.error;
        break;
      }

      case actions.GET_ROLES: {
        const { payload } = action;
        draft[modelKeys.ROLES] = payload;
        break;
      }

      case actions.SAVE_CONTACT_DATA: {
        draft[modelKeys.ONBOARDING].contactData = action.payload;
        break;
      }

      case actions.SAVE_ADDRESS_BILLING: {
        draft[modelKeys.ONBOARDING].addressBilling = action.payload;
        break;
      }

      case actions.SAVE_BUSINESS_DATA: {
        draft[modelKeys.ONBOARDING].businessData = action.payload;
        break;
      }

      case actions.GET_STATUS_PARTNER: {
        draft[modelKeys.IS_GETTING_STATUS_PARTNER] = true;
        break;
      }

      case successAction({ type: actions.GET_STATUS_PARTNER }): {
        draft[modelKeys.IS_GETTING_STATUS_PARTNER] = false;
        break;
      }

      case failAction({ type: actions.GET_STATUS_PARTNER }): {
        draft[modelKeys.IS_GETTING_STATUS_PARTNER] = false;
        draft[modelKeys.ERROR_MESSAGE] = action.error;
        break;
      }

      case actions.SEND_CREATION_PARTNER: {
        draft[modelKeys.ONBOARDING].isSendingCreationPartner = true;
        break;
      }

      case successAction({ type: actions.SEND_CREATION_PARTNER }): {
        draft[modelKeys.ONBOARDING].isSendingCreactionPartner = false;
        draft[modelKeys.ONBOARDING].errorGenericOnCreatePartner = false;
        draft[modelKeys.ONBOARDING].errorTaxNumberDuplicate = false;
        draft[modelKeys.ONBOARDING].errorTaxnumberInvalid = false;
        draft[modelKeys.ONBOARDING].errorComercialNameDuplicate = false;
        draft[modelKeys.ONBOARDING].partnerWasCreated = true;
        break;
      }

      case failAction({ type: actions.SEND_CREATION_PARTNER }): {
        draft[modelKeys.ONBOARDING].isSendingCreationPartner = false;
        const { error } = action;
        if (error?.data) {
          if (error?.data.type === modelKeys.TYPE_ERROR_CREATE_PARTNER.TAXNUMBER_DUPLICATE) {
            draft[modelKeys.ONBOARDING].errorTaxNumberDuplicate = true;
          } else if (
            error?.data.type === modelKeys.TYPE_ERROR_CREATE_PARTNER.COMMERCIAL_NAME_DUPLICATE
          ) {
            draft[modelKeys.ONBOARDING].errorComercialNameDuplicate = true;
          } else if (error?.data.type === modelKeys.TYPE_ERROR_CREATE_PARTNER.TAXNUMBER_INVALID) {
            draft[modelKeys.ONBOARDING].errorTaxnumberInvalid = true;
          } else {
            draft[modelKeys.ONBOARDING].errorGenericOnCreatePartner = true;
          }
        } else {
          draft[modelKeys.ONBOARDING].errorGenericOnCreatePartner = true;
        }
        break;
      }

      case actions.RESET_ERRORS_ON_CREATE_PARTNER: {
        draft[modelKeys.IS_SENDING_CREATION_PARTNER] = false;
        draft[modelKeys.ONBOARDING].errorGenericOnCreatePartner = false;
        draft[modelKeys.ONBOARDING].errorTaxNumberDuplicate = false;
        draft[modelKeys.ONBOARDING].errorTaxnumberInvalid = false;
        draft[modelKeys.ONBOARDING].errorComercialNameDuplicate = false;
        draft[modelKeys.ONBOARDING].partnerWasCreated = false;
        break;
      }

      case actions.GET_CONTACT_DATA: {
        draft[modelKeys.MY_PROFILE_DATA][modelKeys.MY_CONTACT_DATA] = {
          fetching: true,
        };
        break;
      }

      case successAction({ type: actions.GET_CONTACT_DATA }): {
        const { payload } = action;
        draft[modelKeys.MY_PROFILE_DATA][modelKeys.MY_CONTACT_DATA] = {
          ...payload,
          fetching: false,
        };
        break;
      }

      case failAction({ type: actions.GET_CONTACT_DATA }): {
        const { error } = action;
        draft[modelKeys.MY_PROFILE_DATA][modelKeys.MY_CONTACT_DATA] = {
          fetching: false,
          error,
        };
        break;
      }

      case successAction({ type: actions.GET_POSSIBLE_PARTNERS_TO_DO_LOGIN }): {
        const { payload } = action;
        draft[modelKeys.POSSIBLE_PARTNERS_TO_DO_LOGIN] = payload;
        break;
      }

      case failAction({ type: actions.GET_POSSIBLE_PARTNERS_TO_DO_LOGIN }): {
        draft[modelKeys.POSSIBLE_PARTNERS_TO_DO_LOGIN] = [];
        break;
      }

      case actions.IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN: {
        draft[modelKeys.IS_FETCHING_POSSIBLE_PARTNERS_TO_DO_LOGIN] = action.payload;
        break;
      }

      default:
        return draft;
    }
  });
}

export default userModuleReducer;
