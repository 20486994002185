const theme = {
  fonts: {
    muli: 'Muli',
    mulish: 'Mulish',
    lato: 'Lato',
  },
  colors: {
    default: {
      main: '#100423',
      hover: '#100423',
      text: '#FFFFFF',
    },
    primary: {
      main: '#FA0050',
      hover: '#EA044E',
      text: '#FFFFFF',
    },
    secondary: {
      main: '#2D86F4',
      hover: '#2D86F4',
      text: '#FFFFFF',
    },
    success: {
      darkest: '#01C98C',
      dark: '#01C98C',
      medium: '#01C98C',
      light: '#01C98C',
      lightest: '#01C98C',
      main: '#01C98C',
      text: '#273239',
    },
    informative: {
      darkest: '#878290',
      dark: '#878290',
      medium: '#878290',
      light: '#EFEDF0',
      lightest: '#E5F3FF',
      main: '#878290',
      text: '#273239',
    },
    warning: {
      darkest: '#FFB526',
      dark: '#FFB526',
      medium: '#FFB526',
      light: '#FAF3A2',
      lightest: '#FAF3A2',
      main: '#FFB526',
      text: '#100423',
    },
    error: {
      darkest: '#C20018',
      dark: '#C20018',
      medium: '#C20018',
      light: '#FEE1E0',
      lightest: '#FEE1E0',
      main: '#C20018',
      text: '#273239',
    },
    neutral: {
      darkest: '#000000',
      dark: '#273239',
      medium: '#767F86',
      light: '#A9B1B7',
      lightest: '#FFFFFF',
    },
    text: {
      main: '#273239',
      label: '#767F86',
      primary: '#FA0050',
      secondary: '#100423',
    },
    border: {
      main: '#DDE6EE',
    },
    hover: {
      dark: 'rgba(0,0,0,0.05)',
      light: 'rgba(255,255,255,0.2)',
    },
    background: {
      light: 'rgb(247, 248, 249)',
    },

    // TODO: Remove or update old colors
    White: 'rgb(255, 255, 255)',
    Dark: 'rgb(43, 26, 70, 1)',
    Red: 'rgb(250, 0, 80)',
    Green: 'rgb(1, 201, 140)',
    Main: 'rgb(250, 0, 80)',
    DarkBlue: 'rgb(43 26 70)',
    BrandMain: 'rgb(250, 0, 80)',
    BrandSecondary: 'rgb(45, 134, 244)',
    ContentLoader: 'rgb(239, 244, 249)',
    Elephant: 'rgb(39, 50, 57)',
    Gray: 'rgb(184, 180, 188, 1)',
    Transparent: 'transparent',
    NotificationCard: 'rgb(255, 252, 214)',
    OrderCardSelected: 'rgba(0, 0, 0, 0.08)',
    corporateTurquoiseTransparent: 'rgb(0, 189, 237, 0.5)',
    corporateTurquoise: 'rgb(0, 189, 237)',

    buttons: {
      google: 'rgba(45, 134, 244, 1)',
      facebook: 'rgba(59, 89, 152, 1)',
      phone: 'rgb(255, 255, 255)',
      email: 'rgb(255, 255, 255)',
    },

    textPrimary: 'rgb(74, 74, 74)',
    textSecondary: 'rgb(118, 127, 134)',
    textTertiary: 'rgb(250, 0, 80)',
    textHint: 'rgb(169, 177, 183)',
    // error: 'rgb(194, 0, 24)',

    elementLine: 'rgb(221, 230, 238)',
    elementLightGrey: 'rgb(246, 248, 248)',
    elementDisable: 'rgb(218, 224, 229)',

    boxShadow: 'rgb(204, 204, 204)',
    backgroundBody: 'rgb(247, 248, 249)',
    backgroundGrey: 'rgb(252, 252, 254)',
    rowErrorBackground: 'rgb(250, 0, 80, .1)',

    dividerGrey: 'rgb(240, 240, 240)',
    modalOverlay: 'rgba(0,0,0, 0.3)',
    navBackGround: 'rgb(250, 0, 80)',

    grayTransparent: 'rgb(205, 205, 205, 0.3)',

    elementLineTransparent: 'rgb(221, 230, 238, 0.4)',

    pending: 'rgb(118, 127, 134)',
    picked_up: 'rgb(74, 74, 74)',
    cancelled: 'rgb(169, 177, 183)',
    completed: 'rgb(1, 201, 140, .1)',

    core: {
      darkOnDefault: '#100423',
    },

    utilities: {
      secondaryLighter: '#DBF9FE',
      secondaryDarker: '#002CC1',
    },

    complementary: {
      loud: '#2B1A46',
      quiet: '#EFEDF0',
    },
  },
  shadows: {
    main: '0 2px 6px 0 rgba(39, 50, 57, 0.15)',
    card: '0px 2px 6px rgba(0, 0, 0, 0.2)',
  },
  containerShadow: '0 2px 6px 0 rgba(39, 50, 57, 0.15)',
  breakpoints: {
    medium: '640px',
    large: '960px',
    xlarge: '1280px',
    xxlarge: '1600px',
  },
  spaces: {
    none: '0px',
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '20px',
  },
  sizes: {
    main: {
      xs: '12px',
      sm: '16px',
      md: '20px',
      lg: '24px',
      xl: '28px',
    },
    radius: {
      xs: '2px',
      sm: '4px',
      md: '6px',
      lg: '8px',
      xl: '10px',
    },
    component: {
      small: '24px',
      medium: '32px',
      large: '40px',
    },
  },
  typography: {
    fontFamilies: {
      muli: '"Muli", sans-serif',
      mulish: '"Mulish", sans-serif',
      lato: '"Lato", sans-serif',
    },
    fontWeights: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      bolder: 900,
    },
    fontSizes: {
      xs: '8px',
      sm: '10px',
      md: '12px',
      lg: '14px',
      xl: '16px',
      xxl: '18px',
      xxxl: '22px',
    },
    default: {
      fontFamily: '"Muli", sans-serif',
      fontWeight: 'normal',
      lineHeight: '1.3',
      fontSize: '14px',
    },
  },
  orderStatuses: {
    pending: 'rgb(118, 127, 134)',
    in_progress: 'rgb(74, 74, 74)',
    near_pickup: 'rgb(74, 74, 74)',
    picked_up: 'rgb(74, 74, 74)',
    near_dropoff: 'rgb(74, 74, 74)',
    cancelled: 'rgb(169, 177, 183)',
    completed: 'rgb(1, 201, 140)',
  },
};

export default theme;
