import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@components/Icons/CloseIcon';
import Icon from '@components/ClosableTooltip/styled-components/Icon';
import Container from '@components/ClosableTooltip/styled-components/Container';
import Text from '@components/ClosableTooltip/styled-components/Text';
import ContentContainer from '@components/ClosableTooltip/styled-components/ContentContainer';

function ClosableTooltip({ title, text, onClose }) {
  return (
    <Container>
      <ContentContainer>
        <Icon alt="close" width="12px" onClick={onClose}>
          <CloseIcon fill="white" />
        </Icon>
        <Text>
          {title && <div>{title}</div>}
          {text && <div>{text}</div>}
        </Text>
      </ContentContainer>
    </Container>
  );
}

ClosableTooltip.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default ClosableTooltip;
