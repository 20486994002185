import { createSelector } from 'reselect';
import { MODULE_NAME } from '@funWithFlags/constants';

const getPropFromModule = (state, ...props) => state[MODULE_NAME][props];

const getAllFlags = createSelector(
  (state) => getPropFromModule(state, 'flags'),
  (flags) => flags,
);

export default getAllFlags;
