import styled from 'styled-components';

const MobileNavLinksContainer = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: block;
  }
`;

export default MobileNavLinksContainer;
