import { GENERIC_ERROR } from '@utils/genericError';
import ServiceResponse from '../serviceResponse';
import { doLogoutUrl } from '../URL';

export async function logout({ axios }) {
  try {
    const response = await axios.post(doLogoutUrl());
    return new ServiceResponse(response);
  } catch (error) {
    return new ServiceResponse({ error: error.response || GENERIC_ERROR });
  }
}
