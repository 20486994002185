import React, { Suspense, lazy, useEffect, useState, useCallback } from 'react';
import partnerHasChangedEventBuilder from '@global/events/partnerHasChangedEvent';
import { injectIntl } from 'react-intl';
import showLoaderEvent from '@global/events/showLoaderEvent';

import AsyncSelect from 'react-select/async';
import UserService from '@app/services/UserService';
import useRefreshPublicEnv from '@app/hooks/useRefreshPublicEnv';
import messages from './messages';

const LazyPartnerSelectorConfirmDialog = lazy(() => import('./PartnerSelectorDialog'));
const LazyPartnerSelectorLoading = lazy(() => import('./PartnerSelectorLoading'));

const SELECT_STYLES = {
  container: provided => ({
    ...provided,
    width: '300px',
    margin: '0 20px 0 0',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9000,
    color: 'rgb(250, 0, 80)',
    fontSize: '0.9rem',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white',
  }),
  control: provided => ({
    ...provided,
    color: 'white',
    borderColor: 'white',
    outline: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'red',
  }),
  input: provided => ({
    ...provided,
    color: 'red',
  }),
};

const PartnerSelector = ({
  possiblePartnersToDoLogin,
  selectedPartner,
  doUpdateUserLogged,
  fetchPossiblePartnersToDoLogin,
  isFetchingPossiblesPartnersToDoLogin,
  intl,
}) => {
  const [partner, setPartner] = useState(null);
  const [previousPartner, setPreviousPartner] = useState(null);
  const isLoading = !possiblePartnersToDoLogin?.length;
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [partners, setPartners] = React.useState(null);
  const refreshPubEnv = useRefreshPublicEnv();

  useEffect(() => {
    if (selectedPartner && !isFetchingPossiblesPartnersToDoLogin && !possiblePartnersToDoLogin) {
      fetchPossiblePartnersToDoLogin();
    } else {
      setPartners(possiblePartnersToDoLogin);
    }
  }, [possiblePartnersToDoLogin]);

  useEffect(() => {
    if (partners?.length && selectedPartner) {
      const result = partners?.find(i => i.value === Number(selectedPartner?.id));

      if (result) {
        setPartner(result);
      } else if (result === undefined && selectedPartner?.name) {
        const fetch = async () => {
          const searchResult = await UserService.fetchPartners(selectedPartner.name);
          const partnerFound = searchResult?.data[0];
          if (partnerFound) {
            setPartners(prevePartners => [...prevePartners, partnerFound]);
            setPartner(partnerFound);
          }
        };

        fetch();
      }
    }
  }, [selectedPartner, partners]);

  const onChangedHandler = item => {
    if (partner.value !== item.value) {
      setIsOpenDialog(true);
      setPreviousPartner(partner);
      setPartner(item);
    } else {
      setIsOpenDialog(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const searchPartners = useCallback(inputValue => {
    if (inputValue.length >= 3) {
      return new Promise(resolve => {
        setTimeout(async () => {
          const result = await await UserService.fetchPartners(inputValue);

          resolve(result?.data || []);
        }, 500);
      });
    }
  }, []);

  const onCancel = () => {
    setIsOpenDialog(false);
    setPartner(previousPartner);
  };

  const onConfirm = async newPartner => {
    window.dispatchEvent(showLoaderEvent());
    setIsRefreshing(true);
    setIsOpenDialog(false);

    setPartner(newPartner);
    const { data } = await UserService.refreshSession({ partnerId: newPartner.value });
    if (data) {
      setIsRefreshing(false);
      const { data: result } = data;
      window.dispatchEvent(partnerHasChangedEventBuilder({ partner: result?.selectedPartner }));
      doUpdateUserLogged(result);
    }
    await refreshPubEnv();
  };

  return (
    <>
      <AsyncSelect
        styles={SELECT_STYLES}
        defaultOptions={partners?.length ? partners : []}
        value={partner}
        noOptionsMessage={() => intl.formatMessage(messages.partnerSelectorNoResult)}
        placeholder={intl.formatMessage(messages.partnerSelectorPlaceholder)}
        loadOptions={searchPartners}
        isLoading={isLoading}
        loadingMessage={() => intl.formatMessage(messages.partnerSelectorIsLoading)}
        onChange={onChangedHandler}
      />
      {isRefreshing && (
        <Suspense fallback={<span />}>
          <LazyPartnerSelectorLoading loadingText={intl.formatMessage(messages.selectLoading)} />
        </Suspense>
      )}

      {isOpenDialog && (
        <Suspense fallback={<span />}>
          <LazyPartnerSelectorConfirmDialog
            isOpen={isOpenDialog}
            partner={partner}
            previousPartner={previousPartner}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        </Suspense>
      )}
    </>
  );
};

export default injectIntl(PartnerSelector);
