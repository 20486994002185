import styled from 'styled-components';

const HeaderContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  box-shadow: ${props => props.theme.containerShadow};
  background-color: ${props => props.theme.colors.navBackGround};
  margin: 0;
  padding: 0 6%;
  min-height: 70px;
  width: 100%;
  pointer-events: ${props => (props.disabled ? 'none' : 'default')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    padding: 0 5vw;
  }
`;

export default HeaderContainer;
