import styled from 'styled-components';

const Container = styled.div`
  border-radius: 6px;
  position: relative;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.White};
  padding: 5%;
  box-shadow: ${props => props.theme.containerShadow};
`;

export default Container;
