import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PillContainer = styled.div`
  color: ${props => props.theme.colors.utilities.secondaryDarker};
  font-family: ${props => props.theme.fonts.muli};
  font-style: normal;
  font-weight: 800;
  font-size: 0.85em;
  line-height: 12px;
  background-color: ${props => props.theme.colors.utilities.secondaryLighter};
  display: inline-block;
  border-radius: 10px;
  padding: 2px 8px 2px 8px;
  height: 16px;
`;

// TODO: implement different colors and styles
const Pill = ({ text }) => <PillContainer>{text}</PillContainer>;

Pill.propTypes = {
  text: PropTypes.string,
};

export default Pill;
