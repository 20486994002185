const failAction = ({ type, error }) => {
  if (error)
    return {
      type: `${type}_FAIL`,
      error:
        error?.response?.statusText ||
        error?.response ||
        error.message ||
        error,
    };

  return `${type}_FAIL`;
};

export default failAction;
