import styled from 'styled-components';

const ContentContainer = styled.span`
  flex-direction: row-reverse;
  display: flex;
  visibility: visible;
  width: 273px;
  height: 76px;
  background-color: ${props => props.theme.colors.complementary.loud};
  box-shadow: ${props => props.theme.containerShadow};
  color: #fff;
  font-weight: normal;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  margin-left: -117px;
  color: ${props => props.theme.colors.White};
  top: 20px;
  left: 50%;
  &::after {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${props =>
      `transparent transparent ${
        props.theme.colors.complementary.loud
      } transparent`};
  }
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
`;

export default ContentContainer;
