import {
  IS_FETCHING_APP_INIT,
  SELECTED_COUNTRY,
  SESSION_ID,
  CAPTCHA_SITE_KEY,
  DIFF_TIME_IN_MINUTES,
  CURRENT_TOAST,
} from './constants';

const ModuleStateModel = {
  [IS_FETCHING_APP_INIT]: false,
  [SELECTED_COUNTRY]: {},
  [SESSION_ID]: '',
  [CAPTCHA_SITE_KEY]: '',
  [DIFF_TIME_IN_MINUTES]: 0,
  [CURRENT_TOAST]: 0,
};

export default ModuleStateModel;
