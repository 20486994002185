class ServiceResponse {
  constructor({ status, data, error }) {
    if (status >= 200 && status <= 399) {
      this.success = true;
      this.data = data;
    } else {
      this.success = false;
      let errorMessage = '';
      if (error && error.data) {
        // eslint-disable-next-line no-unused-expressions
        error.data?.messages?.forEach((x, i) => {
          errorMessage += `${i} => ${x}`;
        });
      }
      this.error = data?.code || errorMessage || error;
    }
  }
}

export default ServiceResponse;
