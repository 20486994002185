import { navigateToMyOrders } from '@services/routes/navigator';

import { getAllowedMessagers } from './allowedMessagers';
import { GOTO_MY_ORDERS_MESSAGE } from './eventTypes';

export const createPostMessageHandler = (
  history,
  countryShortName,
) => event => {
  const isAllowed = getAllowedMessagers(countryShortName).reduce(
    (prev, curr) => prev || curr.startsWith(event.origin),
    false,
  );

  if (!isAllowed || !history) return;

  // Else
  // You can extract message from here
  const { type } = event.data;

  // TODO: extract every case to functions
  switch (type) {
    case GOTO_MY_ORDERS_MESSAGE:
      navigateToMyOrders();
      break;
    default:
      break;
  }
};
