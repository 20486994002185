/* eslint-disable no-unused-expressions */
// /* eslint-disable no-undef */
let env;

export const getEnv = () => env;
export const setEnv = ({ environment }) => {
  const envToSet = environment === LOCAL_ENVIRONMENT ? DEV_ENVIRONMENT : environment;
  window.ENV = envToSet;
  env = envToSet;
};

export const STG_ENVIRONMENT = 'stg';
export const PROD_ENVIRONMENT = 'live';
export const LOCAL_ENVIRONMENT = 'local';
export const DEV_ENVIRONMENT = 'dev';

export function isLocal() {
  return env === DEV_ENVIRONMENT;
}

export function isDev() {
  return env === DEV_ENVIRONMENT;
}

export function isStg() {
  return env === STG_ENVIRONMENT;
}
export function isProd() {
  return env === PROD_ENVIRONMENT;
}
