export const TRACKING_NAMES = {
  MY_ADDRESSES: 'my-addresses',
  MY_DELIVERY_LIST: 'my_delivery_list',
  CREATE_DELIVERY: 'create_delivery',
  SUPPORT: 'support',
  LOGIN: 'login',
  LANDING: 'landing',
  HOME: 'home',
};

export const trackRoutesNames = {
  '/my-addresses': TRACKING_NAMES.MY_ADDRESSES,
  '/orders': TRACKING_NAMES.MY_DELIVERY_LIST,
  '/orders/create': TRACKING_NAMES.CREATE_DELIVERY,
  '/support': TRACKING_NAMES.SUPPORT,
  '/': TRACKING_NAMES.LANDING,
};
