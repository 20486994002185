import axios from '@app/services/axios';
import { BASE_URL } from '@app/utils/constants/service.constant';

async function getPartnerInfo({ params, config = {} }) {
  return axios().get(`${BASE_URL}/partner/info`, {
    ...config,
    params,
  });
}

export default getPartnerInfo;
