import isString from '@utils/isString';
const isBoolean = (x) => x === true || x === false;
const isObject = (obj) => typeof obj === 'object';

export default function isVariation(flagValue) {
  const variation = flagValue?.variation || false;

  if (isBoolean(variation)) {
    return variation;
  }

  if (isString(variation)) {
    return variation.includes('Variation');
  }

  if (isObject(variation)) {
    return variation;
  }

  return false;
}
