import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import logout from '@assets/icons/logout-new.svg';
import help from '@assets/icons/help-new.svg';
import HeaderLinkWithIcon from '@global/components/HeaderLinkWithIcon';
import PartnerSelector from '@global/components/PartnerSelector';
import ROUTES from '@services/routes';
import MobileNavLinksContainer from './styled-components/MobileNavLinksContainer';
import HamburgerIconContainer from './styled-components/HamburgerIconContainer';
import MobileNavLinks from './styled-components/MobileNavLinks';
import HeaderLink from './styled-components/HeaderLink';

import messages from './messages';

const MobileNavContainer = ({
  links,
  pushPage,
  location,
  doLogout,
  trackHeaderClick,
  isUserLogged,
  stopNavigation,
  updateRouteSelected,
  intl: { formatMessage },
  disabled,
  showPartnerSelector,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const linksToRender = links.map(link => (
    <HeaderLink
      isMobile
      key={link.route}
      disabled={disabled}
      isSelected={location.pathname === link.route}
      onClick={() => {
        if (!disabled) {
          if (stopNavigation) {
            updateRouteSelected(link.route);
            return;
          }

          setMobileMenuOpen(false);
          trackHeaderClick({
            event: link.trackEvent,
            origin: location.pathname,
          });
          pushPage(link.route);
        }
      }}
    >
      {formatMessage(link.label)}
    </HeaderLink>
  ));
  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  return (
    <MobileNavLinksContainer>
      <HamburgerIconContainer isOpen={mobileMenuOpen} onClick={toggleMenu}>
        <div />
        <div />
        <div />
      </HamburgerIconContainer>
      <MobileNavLinks isOpen={mobileMenuOpen}>
        {showPartnerSelector && <PartnerSelector />}
        {isUserLogged && linksToRender}
        <HeaderLinkWithIcon
          linkIcon={help}
          label={formatMessage(messages.supportCenter)}
          onClick={() => {
            if (stopNavigation) {
              updateRouteSelected(ROUTES.HELP_CENTER);
              return;
            }
            pushPage(ROUTES.HELP_CENTER);
          }}
        />
        {isUserLogged && (
          <HeaderLinkWithIcon
            linkIcon={logout}
            label={messages.logout.defaultMessage}
            onClick={() => {
              setMobileMenuOpen(false);
              doLogout();
            }}
          />
        )}
      </MobileNavLinks>
    </MobileNavLinksContainer>
  );
};

MobileNavContainer.propTypes = {
  links: PropTypes.array,
  doLogout: PropTypes.func,
  pushPage: PropTypes.func,
  location: PropTypes.object,
  disabled: PropTypes.bool,
  trackHeaderClick: PropTypes.func,
  isUserLogged: PropTypes.bool,
  stopNavigation: PropTypes.bool,
  updateRouteSelected: PropTypes.func,
};

export default injectIntl(MobileNavContainer);
