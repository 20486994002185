export const MODULE_NAME = 'global';
export const IS_FETCHING_APP_INIT = 'isFetchingAppInit';
export const SELECTED_COUNTRY = 'selectedCountry';
export const SESSION_ID = 'sessionId';
export const CONTACT_PHONE = 'https://wa.me/59893572884';
export const CAPTCHA_SITE_KEY = 'captchaSiteKey';
export const DIFF_TIME_IN_MINUTES = 'diffTimeInMinutes';
export const CURRENT_TOAST = 'currentToasts';
export const SELECTED_PARTNER = 'selectedPartner';

export const ADDRESS_USE_CASE_TYPE = {
  SIMPLE_ADDRESS: 'STREET_SEARCH_FULL_ADDRESS',
  PANAMA_FORMAT: 'PANAMA_FORMAT',
  FULL_ADDRESS: 'DEFAULT',
  COLOMBIAN_FORMAT: 'COLOMBIAN_FORMAT',
};

export const COUNTRY_SHORT_NAME = {
  DOMINICAN_REPUBLIC: 'do',
  PANAMA: 'pa',
  VENEZUELA: 've',
};
