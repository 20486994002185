import styled from 'styled-components';

const LinkLabel = styled.span`
  color: ${props => props.theme.colors.White};
  font-size: 0.9em;
  align-items: center;
  font-weight: bold;
  display: flex;
  margin-left: 5px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  ${props => props.isSelected && `color: ${props.theme.colors.Red}`}}
`;

export default LinkLabel;
