import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import singleSpaReact from 'single-spa-react';

import pkg from '../package.json';
import Root from './root.component';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  renderType: 'render',
  loadRootComponent: async props => {
    try {
      const fwf = await props.fwfPromise;
      const tracker = await props.trackerPromise;
      const {
        data: { context, initialData },
      } = await axios.get(`/api/${pkg.name}/env`);
      const messages = await import(`../build/translations/${context.locale}.json`);
      // eslint-disable-next-line no-shadow,react/display-name
      return props => (
        <Root
          {...props}
          initialData={initialData}
          context={context}
          tracker={tracker}
          fwf={fwf}
          messages={messages}
        />
      );
    } catch (error) {
      return () => <div error={error} />;
    }
  },
  errorBoundary(error, info, props) {
    return <div error={error} />;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
