import styled from 'styled-components';

const MobileNavLinks = styled.div`
  max-height: 0;
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0;
  background: ${props => props.theme.colors.Main};
  overflow: hidden;
  top: 70px;
  border-top: 1px solid ${props => props.theme.colors.elementLine};
  z-index: 1000;
  box-shadow: ${props => props.theme.containerShadow};
  transition: max-height 0.2s ease-in-out;

  ${props => props.isOpen && `max-height: 300px;`}
  a, div {
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin: 0;
    border-radius: 0;
    border-bottom: 1px solid ${props => props.theme.colors.elementLine};
  }
`;

export default MobileNavLinks;
