import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Location Reducers
import { MODULE_NAME as locationNAME } from '@location/constants';
import locationModuleReducer from '@location/reducer';

// User Reducers
import { MODULE_NAME as userNAME } from '@user/constants';
import userModuleReducer from '@user/reducer';

// Bulk Orders
import { MODULE_NAME as ordersBulk } from '@orders-bulk/constants';
import ordersBulkReducer from '@orders-bulk/reducer';

// Orders Multiple
import { MODULE_NAME as ordersMultiple } from '@orders-multiple/constants';
import ordersMultipleReducer from '@orders-multiple/reducer';

// Share Order Status Reducers
import { MODULE_NAME as shareOrderStatus } from '@share-order-status/constants';
import shareOrderStatusReducer from '@share-order-status/reducer';

// Global reducers
import { MODULE_NAME as globalNAME } from '@global/constants';
import globalModuleReducer from '@global/reducer';

// FWF reducers
import { MODULE_NAME as fwf } from '@funWithFlags/constants';
import fwfModuleReducer from '@funWithFlags/reducer';

// LocationManager reducers
import { MODULE_NAME as locationManagerName } from '@location-manager/constants';
import locationManagerReducer from '@location-manager/reducer';

export default function createReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    [locationNAME]: locationModuleReducer,
    [userNAME]: userModuleReducer,
    [globalNAME]: globalModuleReducer,
    [shareOrderStatus]: shareOrderStatusReducer,
    [ordersBulk]: ordersBulkReducer,
    [ordersMultiple]: ordersMultipleReducer,
    [fwf]: fwfModuleReducer,
    [locationManagerName]: locationManagerReducer,
  });
}
