export const FETCH_PARTNER_BRANCHES =
  'app/location-manager/FETCH_PARTNER_BRANCHES';
export const FETCH_PARTNER_BRANCHES_BY_ID =
  'app/location-manager/FETCH_PARTNER_BRANCHES_BY_ID';
export const FETCH_FREQUENTS_ADDRESSES =
  'app/location-manager/FETCH_FREQUENTS_ADDRESSES';
export const FETCH_FREQUENTS_ADDRESS_BY_TEXT =
  'app/location-manager/FETCH_FREQUENTS_ADDRESS_BY_TEXT';
export const FETCH_BRANCHES_BY_TEXT =
  'app/location-manager/FETCH_BRANCHES_BY_TEXT';
export const FETCH_FREQUENTS_ADDRESSES_BY_ID =
  'app/location-manager/FETCH_FREQUENTS_ADDRESSES_BY_ID';
export const FETCH_ALL_FREQUENTS_ADDRESSES =
  'app/location-manager/FETCH_ALL_FREQUENTS_ADDRESSES';
export const UPDATE_PARTNER_BRANCH =
  'app/location-manager/UPDATE_PARTNER_BRANCH';
export const SAVE_PARTNER_BRANCH = 'app/location-manager/SAVE_PARTNER_BRANCH';
export const DELETE_PARTNER_BRANCH =
  'app/location-manager/DELETE_PARTNER_BRANCH';
export const DELETE_FREQUENTS_ADDRESS =
  'app/location-manager/DELETE_FREQUENTS_ADDRESS';
export const FETCH_AUTOCOMPLETE_ADDRESSES =
  'app/location-manager/FETCH_AUTOCOMPLETE_ADDRESSES';
export const FETCH_PLACE_BY_ID = 'app/location-manager/FETCH_PLACE_BY_ID';
export const FETCH_REVERSE_GEO = 'app/location-manager/FETCH_REVERSE_GEO';
export const CLEAR_ADDRESSES = 'app/location-manager/CLEAR_ADDRESSES';
