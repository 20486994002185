export const WAYPOINTS = {
  DROP_OFF: 'DROP_OFF',
  PICK_UP: 'PICK_UP',
};

export const ERRORS = {
  WAYPOINTS_OUT_OF_ZONE: 'WAYPOINTS_OUT_OF_ZONE',
  MAX_DISTANCE_EXCEEDED: 'MAX_DISTANCE_EXCEEDED',
  WAREHOUSE_NOT_FOUND: 'WAREHOUSE_NOT_FOUND',
};
export const LOGISTIC_MODE = {
  CROSS_DOCKING: 'CROSS_DOCKING',
  DROP_OFF: 'DROP_OFF',
};
