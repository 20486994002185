import { GENERIC_ERROR } from '@utils/genericError';
import { SELECT_PARTNER } from '../actionTypes';

const type = SELECT_PARTNER;

const savePartnerSelected = partnerId => async dispatch => {
  try {
    dispatch({ type, payload: partnerId });
  } catch (e) {
    dispatch({
      type,
      error: e || GENERIC_ERROR,
    });
  }
};

export default savePartnerSelected;
