/* eslint-disable no-undef */
import { BrowserClient, Hub } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import isString from '@utils/isString';

const STG_ENVIRONMENT = 'stg';
const PROD_ENVIRONMENT = 'live';
let SENTRY;
export const ERROR_LEVEL = 'error';
export const INFO_LEVEL = 'info';

async function initSentry(sentryConfig) {
  if (!sentryConfig) return null;
  const { dsn, isEnabled, environment } = sentryConfig;

  if (isEnabled) {
    try {
      const client = new BrowserClient({
        dsn,
        integrations: [new Integrations.BrowserTracing()],
        environment,
        release: SENTRY_RELEASE,
        sampleRate: 0.1,
        tracesSampleRate: 0.1,
      });

      const instance = new Hub(client);
      setInstance(instance);
      onSentryInitialized();
      return instance;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return null;
    }
  } else {
    return null;
  }
}

export const sentryConfig = env => ({
  dsn: 'https://de209cb7d14f742f0fd55c2dbcdfe627@o4504046939799552.ingest.us.sentry.io/4507330481356800',
  isEnabled: env === PROD_ENVIRONMENT || env === STG_ENVIRONMENT,
  environment: env,
});

let buffer = [];
let bufferActions = [];
const MAX_ACTIONS_TO_KEEP = 30;

export const setBufferActions = action => {
  if (action) {
    const actionToPush = { type: action?.type };
    if (action.error) actionToPush.error = action.error;
    if (bufferActions.length === MAX_ACTIONS_TO_KEEP) {
      bufferActions.shift();
    }

    bufferActions.push(actionToPush);
  } else bufferActions = [];
};

export function logError({ message, error = {}, data }) {
  try {
    const errorMessage = error?.message || '';
    const hasData = Boolean(data);
    const loggerMessage = `${message ? `${message} | ` : ''} ${errorMessage}`;

    let contextData = '';

    if (hasData) {
      contextData = `Data => ${data && JSON.stringify(data)}`;
      if (!message && error instanceof Error) {
        errorLog(error, {
          captureContext: loggerMessage,
        });
      } else {
        logEvent(loggerMessage, {
          data: contextData,
          level: ERROR_LEVEL,
        });
      }
    } else {
      logEvent(loggerMessage, {
        level: ERROR_LEVEL,
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(`[LOGGER][ERROR] ${err.message}`);
  }
}

export function logEvent(message, { data, level = INFO_LEVEL } = {}) {
  const instance = SENTRY;
  if (instance) {
    instance.withScope(scope => {
      scope.setLevel(level);
      scope.setFingerprint([message]);
      if (data) scope.setExtra('data', data);
      if (bufferActions.length) {
        scope.setExtra('Redux actions: ', bufferActions.reverse());
        setBufferActions();
      }
      instance.captureMessage(message);
    });
  } else {
    buffer.push({ level, data, message });
  }
}

function errorLog(err, data) {
  const instance = SENTRY;
  if (instance) {
    instance.withScope(scope => {
      if (bufferActions.length) {
        scope.setExtra('Redux actions: ', bufferActions.reverse());
        setBufferActions();
      }
      if (data) scope.setExtra('data', data);
      instance.captureException(err);
    });
  } else {
    buffer.push({ err, data });
  }
}

function onSentryInitialized() {
  buffer.forEach(x => {
    const { error } = x;
    const { data, message } = x;
    if (err) logError({ message, error, data });
    else logEvent(message, data);
  });
  buffer = [];
}

export function updateUser(sessionId, user) {
  if (!SENTRY || !sessionId) return;
  SENTRY.configureScope(scope => {
    // eslint-disable-next-line no-underscore-dangle
    const oldUserData = { ...scope._user };
    const newUserData = {};

    if (user.id) {
      newUserData.userId = user.id;
    } else {
      newUserData.userId = sessionId;
    }
    if (user.email) {
      newUserData.email = user.email;
    }
    newUserData.sessionId = sessionId;

    scope.setUser({ ...oldUserData, ...newUserData });
  });
}
export function updateCountry(countryShortName) {
  if (!SENTRY || !countryShortName || !isString(countryShortName)) return;

  if (countryShortName) {
    SENTRY.configureScope(scope => {
      scope.setTag('country', countryShortName);
    });
  }
}

function setInstance(instance) {
  SENTRY = instance;
}

export function getInstance() {
  return SENTRY;
}

export default initSentry;
