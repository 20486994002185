import { defineMessages } from 'react-intl';

export default defineMessages({
  partnerSelectorDialogTitle: {
    id: 'src.global.components.partnerSelectorDialog.title',
    defaultMessage: 'Importante',
  },
  partnerSelectorDialogQuestion1: {
    id: 'src.global.components.partnerSelectorDialog.question1',
    defaultMessage: `Actualmente se encuentra en la sucursal <strong>{previous}</strong> y está intentado cambiar a la sucursal <strong>{partner}</strong>.`,
  },
  partnerSelectorDialogQuestion2: {
    id: 'src.global.components.partnerSelectorDialog.question2',
    defaultMessage: `Si tiene algún trabajo en progreso, se perderá.`,
  },
  partnerSelectorDialogQuestion3: {
    id: 'src.global.components.partnerSelectorDialog.question3',
    defaultMessage: `¿Está seguro de que deséa continuar?`,
  },
  partnerSelectorActionCancel: {
    id: 'src.global.components.partnerSelectorDialog.cancel',
    defaultMessage: `Cancelar`,
  },
  partnerSelectorActionConfirm: {
    id: 'src.global.components.partnerSelectorDialog.confirm',
    defaultMessage: `Continuar`,
  },
  partnerSelectorNoResult: {
    id: 'src.global.components.selectPartner.selectDialogNoResults',
    defaultMessage: 'No se encontró el partner (buscar con más de 3 caracteres)',
  },
  partnerSelectorIsLoading: {
    id: 'src.global.components.selectPartner.selectDialogIsLoading',
    defaultMessage: 'Cargando sucursales...',
  },
  partnerSelectorPlaceholder: {
    id: 'src.global.components.selectPartner.partnerSelectorPlaceholder',
    defaultMessage: 'Buscador de sucursales',
  },
  selectLoading: {
    id: 'src.global.components.selectPartner.selectLoading',
    defaultMessage: 'Obteniendo datos de la sucursal',
  },
});
