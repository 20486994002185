import styled from 'styled-components';

const NavLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

export default NavLinksContainer;
