import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import getRolloutDHHelpCenterFlag from '@funWithFlags/selectors/getRolloutDHHelpCenterFlag';
import CareCenter from './care-center';
import ContactCenter from './contact-center';

const ContactSupport = ({
  setCareCenterInstances,
  careCenterInstances,
  setContactCenterInstances,
  contactCenterInstances,
  applyRolloutDHHelpCenter,
}) => {
  const renderCareCenterInstances = () => (
    <>
      {careCenterInstances.map((instance, i) => (
        <CareCenter
          key={instance.id}
          instance={instance}
          index={i}
          setCareCenterInstances={setCareCenterInstances}
          careCenterInstances={careCenterInstances}
        />
      ))}
    </>
  );

  const renderContactCenterInstances = () => (
    <>
      {contactCenterInstances.map((instance, i) => (
        <ContactCenter
          key={instance.id}
          instance={instance}
          index={i}
          setContactCenterInstances={setContactCenterInstances}
          contactCenterInstances={contactCenterInstances}
        />
      ))}
    </>
  );

  return applyRolloutDHHelpCenter ? renderContactCenterInstances() : renderCareCenterInstances();
};

const mapStateToProps = createStructuredSelector({
  applyRolloutDHHelpCenter: getRolloutDHHelpCenterFlag,
});

export default connect(mapStateToProps, {})(ContactSupport);
