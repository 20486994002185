import styled from 'styled-components';

const Header = styled.div`
  border: none;
  width: 100%
  display: flex;
  justify-content: space-between;
  height: 36px;
  background-color: ${props => props.theme.colors.DarkBlue};  
  font-family: ${props => props.theme.fonts.muli};
  color: ${props => props.theme.colors.White};
  cursor: ${props => (!props.isVisible ? 'pointer' : 'default')};
  font-weight: bold;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 6px 6px 0 0;
`;

export default Header;
