import { DO_LOGIN } from '@user/actionTypes';
import successAction from '@global/actions/successAction';
import failAction from '@global/actions/failAction';
import ROUTES from '@services/routes';
import { isEmptyObject } from '@utils/object';
import { loginFailed } from '@tracking/actions/login';
import { trackUserIdentify } from '@tracking/actions/trackUserIdentyfy';
import { GENERIC_ERROR } from '@utils/genericError';
import { logError } from '@logs/logger';
import doLogout from './doLogout';

const type = DO_LOGIN;

const doUpdateUserLogged = user => async dispatch => {
  try {
    if (user && !isEmptyObject(user) && window.location.pathname !== ROUTES.SESSION_EXPIRED) {
      await dispatch(
        successAction({
          type: DO_LOGIN,
          payload: user,
        }),
      );
      await dispatch(trackUserIdentify(user));
    } else {
      doLogout();
    }
  } catch (e) {
    dispatch(loginFailed());
    dispatch(
      failAction({
        type,
        error: e || GENERIC_ERROR,
      }),
    );
    logError({
      message: `Exception on Action (dispatch) Dispatching failure action: ${type}`,
      error: e || GENERIC_ERROR,
    });
  }
};

export default doUpdateUserLogged;
