export const currencies = {
  symbols: {
    AR: '$',
    BO: 'Bs',
    CL: '$',
    CO: '$',
    CR: '₡',
    EC: '$',
    SV: '$',
    GT: '$',
    NH: 'L',
    MX: '$',
    NI: 'C$',
    PA: '$',
    PY: 'Gs.',
    PE: 'S/.',
    PR: '$',
    DO: '$',
    UY: '$',
    VE: '$',
    HN: 'L',
  },
  isoCode: {
    AR: 'ARS',
    BO: 'BOB',
    CL: 'CLP',
    CO: 'COP',
    CR: 'CRC',
    EC: 'USD',
    SV: 'USD',
    GT: 'GTQ',
    NH: 'HNL',
    MX: 'MXN',
    NI: 'NIO',
    PA: 'USD',
    PY: 'PYG',
    PE: 'PEN',
    PR: 'USD',
    DO: 'DOP',
    UY: 'UYU',
    VE: 'USD',
    HN: 'HNL',
  },
};

export const PLATFORM_KEY = {
  PEDIDOS_YA: 'PEDIDOS_YA',
  APPETITO_24: 'APETITO_24',
  DOMICILIOS: 'DOMICILIOS',
};

export const OS = {
  ANDROID: 'Android',
  IOS: 'iOS',
  UNKNOWN: 'unknown',
};

export const COURIER_SUPERVISOR = 'COURIER_SUPERVISOR';
export const COURIER_MONITOR = 'COURIER_MONITOR';
export const COURIER_OPERATOR = 'COURIER_OPERATOR';
export const COURIER_API = 'COURIER_API';

export const APPLICATION_VERSION = '1.1.02';
export const APPLICATION_SUB_ORIGIN = 'One on One';
export const APPLICATION_SUB_ORIGIN_BULK = 'bulk';

export const ROL_SUPERVISOR = 'SupervisorShippingWEB';
export const ROL_OPERATOR = 'OperatorShippingWEB';
export const ROL_MONITOR = 'MonitorShippingWEB';
